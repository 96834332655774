import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

import { Display } from "./Display";
import { Ratio, Ratios } from "./Ratio";

export function Size({ id }: { id?: ID }) {
  const [fullControl, setFullControl] = useState(false);
  const className = "xxsm:w-full sm:w-full h-[44px]";

  const { setInput, input } = Generation.Image.Input.use(id);
  const bounds = Size.Bounds.use(id);

  const onClick = useCallback(
    (value: number) => {
      setInput((input) => {
        input.width = value;
        input.height = value;
      });
    },
    [setInput],
  );

  const options = [
    // TODO: Add this option later
    //{
    //   value:512,
    //   name:"512x512",
    //   image:"size_target.svg"
    // },
    {
      value: 1024,
      name: "1024x1024",
      //      image:"size_target.svg"
    },
  ];

  if (!bounds || !input) return null;
  return (
    <>
      {/* <div className="flex flex-row bg-brand-200 rounded hover:bg-brand-25"> */}
      {/* <img className="overflow-hidden self-end pb-2.5 pl-1" alt="" src="size_target.svg" />       */}
      <Theme.Popout
        title="Target"
        value={input.width}
        className={className}
        onClick={onClick}
        options={options}
        anchor="bottom"
        iconLeft={<img className="pr-1" src="size_target.svg" />}
      ></Theme.Popout>
      {/* </div> */}
    </>
  );
}

export declare namespace Size {
  export { Display, Ratio, Ratios };
}

export namespace Size {
  Size.Display = Display;
  Size.Ratio = Ratio;
  Size.Ratios = Ratios;

  export type Bounds = {
    length: { min: number; max: number };
    area: { max: number };
  };

  export namespace Bounds {
    export const get = (input: Generation.Image.Input) => {
      if (!input?.model) return undefined;

      if (input.model.includes("xl")) {
        return {
          length: {
            min: 512,
            max: 896,
          },

          area: {
            max: 512 * 896,
          },

          ratio: {
            min: 0.57,
            max: 1.75,
          },

          width: {
            min: 512,
            max: 896,
          },

          height: {
            min: 512,
            max: 896,
          },
        };
      }

      const maxArea = 1024 * 1024;
      const maxLength = 2048;
      const minLength = Generation.Image.Model.StableDiffusionV1.baseResolution(
        input.model,
      );

      return {
        length: {
          min: minLength,
          max: maxLength,
        },

        width: {
          min: minLength,
          max: maxArea / input.height,
        },

        height: {
          min: minLength,
          max: maxArea / input.width,
        },

        area: {
          min: minLength * minLength,
          max: maxArea,
        },

        ratio: {
          min: minLength / maxLength,
          max: maxLength / minLength,
        },
      };
    };

    export const use = (id?: ID) => {
      const { input } = Generation.Image.Input.use(id);

      return useMemo(() => input && get(input), [input]);
    };
  }
}
