import * as AlchemyStudio from "@stability/alchemy-studio-plugin";
import { Polling } from "@stability/alchemy-studio-plugin";
import throttledQueue from "throttled-queue";
import { App } from "~/App";
import { Generation } from "~/Generation";
import { GlobalState } from "~/GlobalState";
import { Plugin } from "~/Plugin";

import { UIMessage } from "~/UIMessage";

import { Button } from "./Button";
import { Result } from "./Result";

export declare namespace Create {
  export { Button, Result };
}

export namespace Create {
  Create.Button = Button;
  Create.Result = Result;

  type Handlers = {
    onStarted?: (output?: Generation.Image.Output) => void;
    onException?: (error: Generation.Image.Exception) => void;
    onSuccess?: (images: Generation.Images) => void;
    onFinished?: (result: Generation.Image.Exception | Generation.Images) => void;
  };

  namespace Throttle {
    const requestsPerInterval = 1;
    const interval = 500;
    const spaceEvenly = true;

    const queue = throttledQueue(requestsPerInterval, interval, spaceEvenly);
    export const wait = () => queue(() => Promise.resolve());
  }

  /**
   * Extend image generation response until the image count is reached to requested by client
   * Function called after every POLLING_INTERVAL_SECONDS SEC for POLLING_TIMEOUT_MILLISECONDS timeout
   * @param options.taskid taskid
   * @param options.taskid image count
   * @param options.input input params
   * @param options.handlers functions to update output state
   * @returns
   */
  export const extendGenerateImageResponse = async (options: {
    accessToken: string;
    count: number;
    input: Generation.Image.Input;
    handlers: Handlers;
  }): Promise<Polling.AsyncData<Generation.Images>> => {
    const { accessToken, input, handlers, count } = { ...options };
    const { getGenerateImageTaskResult } = Plugin.get();
    try {
      if (!getGenerateImageTaskResult) throw new Error(UIMessage.ERR_PLUGIN_NOT_FOUND);
      const taskId = input.id;

      //Query task status
      const response = await getGenerateImageTaskResult({ accessToken, taskId });

      //Convert task data to image state objects
      const images: Generation.Images = [];
      for (const image of response.results) {
        const img: AlchemyStudio.StableDiffusionImage = {
          ...image,
          input,
          createdAt: image.created,
          imageUrl: image.compute_url,
          thumbnailUrl: image.thumbnail_url,
        };
        const cropped = await cropImage(img, input);

        if (!cropped) continue;
        images.push({ ...cropped, outputID: taskId });
      }

      /** Update zustand state of output */
      if (handlers.onSuccess) {
        handlers.onSuccess(images);
      }
      if (handlers.onFinished) {
        handlers.onFinished(images);
      }

      //Stop polling if task has same result count as requested by client
      if (response && response.results.length == count) {
        return Promise.resolve({
          done: true,
          data: images,
        });
      } else {
        //Promise to continue polling
        return Promise.resolve({
          done: false,
        });
      }
    } catch (err) {
      //Update zustand state with exception
      const exception = Generation.Image.Exception.create(err);
      if (handlers.onException) {
        handlers.onException(exception);
      }
      if (handlers.onFinished) {
        handlers.onFinished(exception);
      }
      return Promise.reject(err);
    }
  };

  export const execute = async ({
    count = Generation.Image.Count.preset(),
    input,
    onStarted = doNothing,
    onException = doNothing,
    onSuccess = doNothing,
    onFinished = doNothing,
  }: Handlers & {
    count: number;
    input: Generation.Image.Input;
  }): Promise<Generation.Image.Exception | Generation.Images> => {
    const { createStableDiffusionImages } = Plugin.get();
    const POLLING_INTERVAL_SECONDS = 1;
    const POLLING_TIMEOUT_MILLISECONDS = 120 * 1000;
    try {
      if (!createStableDiffusionImages) throw new Error(UIMessage.ERR_PLUGIN_NOT_FOUND);

      Latest.set(new Date());
      onStarted();
      await Throttle.wait();

      const initImg = await Generation.Image.Input.resizeInit(input);
      const accessToken = App.useToken();
      const newInputs: Record<ID, Generation.Image.Input> = {};

      if (!accessToken) throw new Error(UIMessage.MSG_NO_TOKEN);
      const pluginInput = await Generation.Image.Input.toInput(
        !initImg
          ? input
          : {
              ...input,
              init: {
                base64: initImg,
                weight: input.init?.weight ?? 1,
                mask: input.init?.mask ?? false,
              },
            },
      );

      if (!Generation.Image.Input.isUpscaling(input)) {
        pluginInput.height = Math.ceil((pluginInput.height ?? 1024) / 64) * 64;
        pluginInput.width = Math.ceil((pluginInput.width ?? 1024) / 64) * 64;
      }

      const response = await createStableDiffusionImages({
        accessToken,
        input: pluginInput,
        count,
      });

      if (response instanceof Error) throw response;

      if (!response?.id) throw Error();

      const newInput = {
        ...Generation.Image.Input.initial(response.id),
        ...input,
        seed: input.seed,
        id: response.id,
      };
      newInputs[newInput.id] = newInput;

      Generation.Image.Inputs.set({
        ...Generation.Image.Inputs.get(),
        ...newInputs,
      });

      // Keep polling to successful task creation
      if (!response || !response.id) throw new Error(UIMessage.ERR_FAILED_CREATE_IMAGE);
      //Poll every 1 second for 120 seconds
      const generateImgResponse = await Polling.asyncPoll(
        {
          accessToken,
          count,
          input: newInput,
          handlers: { onStarted, onSuccess, onException, onFinished },
        },
        extendGenerateImageResponse,
        POLLING_INTERVAL_SECONDS,
        POLLING_TIMEOUT_MILLISECONDS,
      );
      return generateImgResponse;
    } catch (caught: unknown) {
      const exception = Generation.Image.Exception.create(caught);

      onException(exception);
      onFinished(exception);

      return exception;
    }
  };

  export const use = () => {
    const showErrorSnackbar = Generation.Image.Exception.Snackbar.use();

    return useCallback(
      async ({
        inputID,
        onStarted = doNothing,
        onException = doNothing,
        onSuccess = doNothing,
        onFinished = doNothing,
        modifiers = {},
      }: {
        inputID: ID;
        modifiers?: Generation.Image.Input.Modifiers;
      } & Handlers) => {
        let input = Generation.Image.Input.get(inputID);
        if (!input) return;

        input = {
          ...input,
          ...modifiers,
        };

        const output = Generation.Image.Output.requested(inputID, modifiers);

        return execute({
          count: modifiers.count ?? Generation.Image.Count.get(),
          input,
          onStarted: () => {
            Generation.Image.Output.set(output);
            onStarted(output);
          },

          onException: (exception) => {
            showErrorSnackbar(exception);
            onException(exception);
            Generation.Image.Output.clear(output.id);
            Generation.Image.Create.Latest.set(undefined);
          },

          onSuccess: (images) => {
            images.forEach(Generation.Image.add);
            onSuccess(images);
          },

          onFinished: (result) => {
            Generation.Image.Output.received(output.id, result);
            onFinished(result);
          },
        });
      },
      [showErrorSnackbar],
    );
  };

  export const useIsEnabled = () =>
    Plugin.use(({ createStableDiffusionImages }) => !!createStableDiffusionImages);

  export type Latest = Date | undefined;
  export namespace Latest {
    export const get = () => State.get().latest;
    export const set = (latest: Latest) => State.get().setLatest(latest);

    export const use = () => State.use(({ latest }) => latest, GlobalState.shallow);

    type State = {
      latest?: Latest;
      setLatest: (latest: Latest) => void;
    };

    namespace State {
      const store = GlobalState.create<State>((set) => ({
        setLatest: (latest: Latest) => set({ latest }),
      }));

      export const get = () => store.getState();
      export const use = store;
    }
  }
}

// TODO: Move somewhere else
function cropImage(
  image: AlchemyStudio.StableDiffusionImage,
  input: Generation.Image.Input,
) {
  return new Promise<Generation.Image | void>((resolve) => {
    const id = image.id;
    const img = new window.Image();
    img.src = image.thumbnailUrl ?? "";

    return resolve({
      id,
      inputID: input.id,
      created: new Date(),
      src: img.src,
      finishReason: 0,
      actualBlobDownloadUrl: image.imageUrl,
      thumbNailDownloadUrl: image.thumbnailUrl,
      feedback: { like: false, dislike: false },
    });
  });
}
