// Import the functions you need from the SDKs you need
import * as AlchemyStudio from "@stability/alchemy-studio-plugin";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/auth";
import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from "firebase/compat/app";
import {
  collection,
  deleteDoc,
  getCountFromServer,
  getDocs,
  getFirestore,
  limit,
  limitToLast,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { deleteObject, getStorage, ref } from "firebase/storage";

import {
  BackendAPITokenResponse,
  CreateImageReponse,
  FirebaseImageModel,
} from "../types";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_PROJECT_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENTID,
};

/**
 * Firebase Errorcodes used to throw functional error
 */
export enum ErrorCodes {
  /** Thrown if a method is called with incorrect arguments. */
  AUTH_INVALID_CREDINTIAL = "auth/invalid-credential",
  USER_NOT_FOUND = "auth/user-not-found",
  INVALID_EMAIL = "auth/invalid-email",
  EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
  // ...
}
export const environment = import.meta.env.VITE_NODE_ENV;

export const logStartTime = (label?: string): void => {
  if (environment == "development") console.time(label);
};

export const logStopTime = (label?: string): void => {
  if (environment == "development") console.timeEnd(label);
};

export const logData = (message: string, object?: any): void => {
  if (environment == "development" && object) {
    console.log(JSON.stringify(object));
  }
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = firebase.auth();
const functions = getFunctions(app, import.meta.env.VITE_FIREBASE_DEFAULT_REGION);
const storage = getStorage(app);
const db = getFirestore(app);
const firebaseStorageApiUrl = "https://firebasestorage.googleapis.com/v0/b/";

/**
 * Build http GET URL to download image blob from firebase bucket storage.
 * @param imagePath location of the image
 * @returns
 */
export const buildFirebaseUrl = (imagePath: string): string => {
  return (
    firebaseStorageApiUrl +
    "" +
    firebaseConfig.storageBucket +
    "/o/" +
    imagePath.replaceAll("/", "%2F") +
    "?alt=media"
  );
};

/**
 * Below method is used to generate image url using firebase bucket location
 * @param imagePath location of the png image
 * @returns
 */
export const getImageUrl = (imagePath: string | undefined): string => {
  if (!imagePath) throw Error("Firebase storage path is mandatory");
  return buildFirebaseUrl(imagePath);
};

/**
 * Build thumbnailurl(.jpeg) using original image location from storage
 * @param imagePath location of the thumbnail image
 * @returns
 */
export const getThumbNailUrl = (imagePath: string | undefined): string => {
  if (!imagePath) throw Error("Firebase storage path is mandatory");
  const imagePathArr = imagePath.split("/", 2);

  if (imagePathArr.length < 2) throw Error("Image path is wrong");
  const uid = imagePathArr[0];
  const thumbNailName = imagePathArr[1].replace(".png", ".jpeg");
  const thumNailLocation = uid + "/thumbs/" + thumbNailName;
  return buildFirebaseUrl(thumNailLocation);
};

export const queryExistingImagesByKeyword = async (
  keyWord: string,
): Promise<FirebaseImageModel[] | undefined> => {
  let uid: string | null | undefined = auth.currentUser?.uid;
  const images: FirebaseImageModel[] = [];
  if (uid === undefined || uid == null) {
    const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);
    if (userInfoJson != null) {
      uid = JSON.parse(userInfoJson)?.uid;
    }
  }
  if (uid) {
    const dbQuery = query(
      collection(db, "user_info", uid, "images"),
      where("prompt", "array-contains", keyWord),
    );
    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      images.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  return images;
};

/**
 * Fetch existing image collection from metadata from firebase database
 * @returns Array of image records
 */
export const queryExistingImages = async (
  paginationData: AlchemyStudio.PaginationData,
): Promise<FirebaseImageModel[] | undefined> => {
  let uid: string | null | undefined = auth.currentUser?.uid;
  const generated_on = Timestamp.fromDate(paginationData.paginationKey);

  const images: FirebaseImageModel[] = [];
  if (uid === undefined || uid == null) {
    const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);
    if (userInfoJson != null) {
      uid = JSON.parse(userInfoJson)?.uid;
    }
  }
  if (uid) {
    let dbQuery = query(
      collection(db, "user_info", uid, "images"),
      orderBy("generated_on", "desc"),
    ); // startAfter(generated_on)

    if (paginationData?.forward === undefined || paginationData?.forward) {
      /** Get next images*/
      dbQuery = query(
        dbQuery,
        where("generated_on", "<", generated_on),
        limit(paginationData.limit),
      );
    } else if (paginationData.forward === false) {
      /** Get previous images*/
      dbQuery = query(
        dbQuery,
        where(
          "generated_on",
          ">",
          new Timestamp(generated_on.seconds + 1, generated_on.nanoseconds),
        ),
        limitToLast(paginationData.limit),
      );
    }

    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      images.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  }
  return images;
};

/**
 * Delete image record from firebase including bucket storage
 * @returns Array of image records
 */
export const deleteExistingImagesFromFirebase = async (
  imageIDs?: AlchemyStudio.ID[],
): Promise<void> => {
  if (!imageIDs) return;
  const uid: string | null | undefined = auth.currentUser?.uid;
  if (uid) {
    for (const imageId of imageIDs) {
      const dbQuery = query(
        collection(db, "user_info", uid, "images"),
        where("id", "==", imageId),
      );

      const querySnapshot = await getDocs(dbQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const imageLocation = data.location;
        const thumbNailName = imageLocation.replace(".png", ".jpeg");
        const thumNailLocation = uid + "/thumbs/" + thumbNailName;

        // Create a reference to the file to delete
        const imageRef = ref(storage, imageLocation);
        const thumbnailRef = ref(storage, thumNailLocation);
        deleteDoc(doc.ref).then(() => {
          deleteObject(imageRef);
          deleteObject(thumbnailRef);
        });
      });
    }
  }
};
/**
 * Find total number of image count to calculate page number for image hisstory display
 */
export const getTotalNumPages = async (pagelimit: number): Promise<number> => {
  let uid: string | null | undefined = auth.currentUser?.uid;
  if (uid === undefined || uid == null) {
    const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);
    if (userInfoJson != null) {
      uid = JSON.parse(userInfoJson)?.uid;
    }
  }
  const dataCollection = collection(db, "user_info", uid ?? "", "images");
  const count = await getCountFromServer(dataCollection);
  const numPages = Math.ceil(count.data().count / pagelimit);
  return numPages;
};

/**
 * Cloud function call to genrate backend token
 * @param input
 * @returns
 */
export const generateBackendToken = async (): Promise<string | undefined> => {
  let apiKey: string | undefined;
  const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);

  if (userInfoJson != null) {
    apiKey = JSON.parse(userInfoJson)?.subnetApitoken;
  }

  if (apiKey === undefined) throw Error("User not autherized. Missing API key");

  const generateBackendTokenFunction = httpsCallable<
    AlchemyStudio.TokenGenerationRequest,
    BackendAPITokenResponse
  >(functions, "generate_backend_token");
  const response = await generateBackendTokenFunction({ api_key: apiKey });
  return response.data.access_token;
};

/**
 * Cloud function to link discord account with web
 * @param input contains oAuth code coming form discord redirect url
 * @returns
 */
export const linkDiscordAccount = async (
  request: AlchemyStudio.DiscordAuthRequest,
): Promise<void> => {
  enum State {
    LOGIN = "LOGIN",
    LINK = "LINK",
  }
  const state = State.LINK;
  const linkDiscordAccount = httpsCallable<
    AlchemyStudio.DiscordAuthRequest,
    BackendAPITokenResponse
  >(functions, "link_discord_account");
  await linkDiscordAccount({ ...request, state });
};

/**
 * Cloud function to Login, Signup with discord account. Returns firebase id token for signin
 * @param request contains oAuth code coming form discord redirect url
 * @returns
 */
export const authorizeDiscordAccount = async (
  request: AlchemyStudio.DiscordAuthRequest,
): Promise<string> => {
  enum State {
    LOGIN = "LOGIN",
    LINK = "LINK",
  }
  const state = State.LOGIN;
  const linkDiscordAccount = httpsCallable<
    AlchemyStudio.DiscordAuthRequest,
    BackendAPITokenResponse
  >(functions, "link_discord_account");
  const response = await linkDiscordAccount({ ...request, state });
  return response.data.access_token;
};

/**
 * Cloud function call to genrate image using prompt,seed
 * @param input
 * @returns
 */
export const generateImage = async (
  input: AlchemyStudio.AlchemyDiffusionPrompt,
): Promise<CreateImageReponse | undefined> => {
  let apiKey: string | undefined;
  const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);
  if (userInfoJson != null) {
    apiKey = JSON.parse(userInfoJson)?.subnetApitoken;
  }
  if (apiKey === undefined) throw Error("User not autherized. Missing API key");
  const generateImageCloudFunction = httpsCallable<
    AlchemyStudio.AlchemyDiffusionPrompt,
    CreateImageReponse
  >(functions, "generate_image_using_api");
  const response = await generateImageCloudFunction({ ...input, api_key: apiKey });
  return response.data;
};

/**
 * get subnet API key for the user
 * @returns
 */
export const getSubnetApikey = async (): Promise<string | undefined> => {
  let uid: string | null | undefined = auth.currentUser?.uid;
  let id: string | undefined;
  if (uid === undefined || uid == null) {
    const userInfoJson = localStorage.getItem(AlchemyStudio.GlobalConstants.USER_INFO);
    if (userInfoJson != null) {
      uid = JSON.parse(userInfoJson)?.uid;
    }
  }
  if (uid) {
    const dbQuery = query(
      collection(db, "user_info", uid, "api_keys"),
      where("is_active", "==", true),
    );
    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      id = doc.id;
    });
  }
  return id;
};

/**
 *
 * @returns Cloud function call to generate subnet API key
 */
export const generateSubnetApiKey = async (): Promise<string | undefined> => {
  interface GenApiKeyResponse {
    expiry?: string;
    generated_on?: string;
    id?: string;
    is_active?: string;
    updated_on?: string;
  }

  const generateApiKey = httpsCallable(functions, "generate_api_key");
  const response = await generateApiKey();

  if (response.data) {
    const data: GenApiKeyResponse = { ...response.data };
    return data?.id;
  }
};

export declare namespace FireBase {
  export {
    app,
    analytics,
    auth,
    sendEmailVerification,
    sendPasswordResetEmail,
    generateSubnetApiKey,
    ErrorCodes,
  };
}

export namespace FireBase {
  FireBase.auth = auth;
  FireBase.analytics = analytics;
  FireBase.app = app;
  FireBase.sendEmailVerification = sendEmailVerification;
  FireBase.sendPasswordResetEmail = sendPasswordResetEmail;
  FireBase.generateSubnetApiKey = generateSubnetApiKey;
  FireBase.ErrorCodes = ErrorCodes;
}
