import { useLocation } from "react-router-dom";
import { Generation } from "~/Generation";

import { Theme } from "~/Theme";

import { Advanced } from "./Advanced";

export function Sidebar() {
  const { input } = Generation.Image.Session.useCurrentInput();
  const location = useLocation();

  if (!input?.id) return null;
  return <Sidebar.Tab id={input.id} />;
}

export namespace Sidebar {
  export function Tab({
    id,
    variant = "generate",
  }: {
    id: ID;
    variant?: "generate" | "editor";
  }) {
    const [settingsOpen, setSettingsOpen] = useState(true);
    const areStylesEnabled = Generation.Image.Styles.useAreEnabled();
    const createDream = Generation.Image.Session.useCreateDream();
    const [advancedMode, setAdvancedMode] = useState(false);
    const { setInput, input } = Generation.Image.Input.use(id);
    const deviceType = Theme.useDevice();
    const isMobileDevice = Theme.useIsMobileDevice();

    const mode = () => {
      return useMemo(() => {
        return (
          <>
            <div className="flex w-full shrink grow flex-col justify-between gap-2 lg:flex-row">
              <div className="flex w-full flex-row items-center">
                <img
                  className=" flex h-8 w-8 pr-1"
                  alt="AlchemyStudio"
                  src="stars-light-sparkle.svg"
                />
                <div className="font-inter text-whitesmoke relative text-left text-lg">
                  Alchemizer
                </div>
              </div>
              <div className="relative flex h-11 w-full flex-row justify-start lg:w-72 lg:justify-end ">
                <div className="bg-brand-200 flex w-full flex-row items-center justify-center self-stretch rounded border-solid p-1">
                  <div
                    className={classes(
                      "z-[1] w-full items-center justify-center self-stretch rounded px-3 py-1.5",
                      advancedMode ? "bg-brand-100" : "",
                    )}
                    onClick={() => {
                      setAdvancedMode(true);
                    }}
                  >
                    <div className="relative cursor-pointer text-center leading-[20px]">
                      Advanced
                    </div>
                  </div>
                  <div
                    className={classes(
                      "z-[1] w-full items-center justify-center self-stretch rounded px-3 py-1.5",
                      !advancedMode ? "bg-brand-100" : "",
                    )}
                    onClick={() => {
                      setAdvancedMode(false);
                    }}
                  >
                    <div className="relative cursor-pointer text-center leading-[20px]">
                      Essential
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </>
        );
      }, [advancedMode]);
    };

    return (
      <div className="flex w-full shrink grow flex-col space-y-4">
        {mode()}
        <div className=" flex flex-row justify-between">
          {!isMobileDevice && (input?.init as any)?.src && (
            <div className="flex h-full shrink-0 flex-col gap-2 px-2">
              <Generation.Image.UploadImage.Display id={id} />
              <Generation.Image.UploadImage.Buttons.EditImage id={id} />
            </div>
          )}

          <div
            className={classes("flex w-2/3 shrink grow flex-col justify-between gap-2")}
          >
            {isMobileDevice && advancedMode && (
              <div>
                <Advanced id={id} defaultExpanded={advancedMode} />
              </div>
            )}

            {isMobileDevice && (input?.init as any)?.src && (
              <div className="relative flex shrink-0 flex-col gap-2 px-2">
                <Generation.Image.UploadImage.Display id={id} />
                <Generation.Image.UploadImage.Buttons.EditImage id={id} />
              </div>
            )}

            <Generation.Image.Prompt.Sidebar.Section
              id={id}
              advancedMode={advancedMode}
            />
            <div className="xxsm:flex-col justify-left flex w-full gap-2 pt-2 md:flex-row">
              <div className="flex flex-row justify-between gap-2">
                <Generation.Image.Count.Button />
                <Generation.Image.Prompt.Shuffle.Button
                  inputID={id}
                  index={0}
                  className="h-[44px]"
                />
              </div>
              <Generation.Image.Create.Button
                id={id}
                onIdleClick={() => createDream()}
                fullWidth
                className={classes(
                  "hover:bg-brand-1100 h-[44px]",
                  !isMobileDevice && "max-w-[300px]",
                )}
              />
              {/* </div> */}
            </div>
          </div>
          {!isMobileDevice && advancedMode && (
            <div
              className={classes(
                "relative flex flex-row pl-2",
                (input?.init as any)?.src ? "w-[300px]" : "w-[276px]",
              )}
            >
              <Advanced id={id} defaultExpanded={advancedMode} />
            </div>
          )}
          {/* </div>  */}
        </div>
      </div>
    );
  }
}
