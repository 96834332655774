export const Routes = {
  LOGIN: "/login",
  PROFILE: "/profile",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgotpassword",
  GENERATE: "/generate",
  DISCORD_AUTH: "/discord/authorize",
  VALIDATE: "/validate",
  EDIT: "/edit",
  SETTINGS: "/settings",
};
