import { WalletAPI } from "~/api/WalletAPI";
import { App } from "~/App";
import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

import { UIMessage } from "~/UIMessage";

import { Wallet } from "..";
import React from "react";

export function SetupWallet({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) {
  const showSnackBar = Generation.Image.Exception.Snackbar.showMessage();
  const [loading, isLoading] = useState(false);
  const [ss58Valid, setSs58Valid] = useState(false);
  const accessToken = App.getToken();

  const [inputAddress, setInputAddress] = useState<string>("");
  const confirmCustomWalletHandler = async () => {
    isLoading(true);
    try {
      if (!inputAddress) throw new Error("Wallet address required");
      if (!Wallet.validateSS58Address(inputAddress)) {
        throw new Error("Wallet address is not SS58 format");
      }
      if (!accessToken) throw new Error(UIMessage.MSG_NO_TOKEN);
      const updatedWallet = await WalletAPI.updateWallet({
        accessToken,
        address: inputAddress,
      });
      Wallet.add({ ...updatedWallet });
      showSnackBar("Wallet updated successfully", "center", "top", "success");
      onConfirm();
      onClose();
    } catch (error) {
      showSnackBar(UIMessage.ERR_SETUP_WALLET, "center", "top", "error");
      throw error;
    } finally {
      isLoading(false);
    }
  };

  const inputAddressChange = (value: string) => {
    setInputAddress(value);
    setSs58Valid(Wallet.validateSS58Address(value));
  };

  return (
    <>
      <div
        className={classes(
          "bg-brand-100 font-inter relative z-[1] flex grow flex-col justify-center gap-4 overflow-hidden rounded-lg p-4",
        )}
      >
        <span className="text-whitesmoke p-2 text-2xl">
          Set a custom Tao wallet address
        </span>
        <div className="items-ceneter flex flex-row gap-2 px-2">
          <Theme.Icon.Info size={20} className="opacity-75" />
          <span className="text-whitesmoke text-sm opacity-50">
            Enter your wallet address below
          </span>
        </div>
        <div className="bg-brand-50 flex min-h-[200px] flex-row flex-col gap-3 rounded-lg p-8">
          <span className="text-whitesmoke text-2xl">Custom Wallets</span>
          <span className="flextext-whitesmoke gap-y-4 text-sm opacity-50">
            <p className="pt-2">
              Please note that any previously stored wallet address will be replaced
              with the new one you provide.
            </p>
          </span>
          <div className="bg-brand-100 rounded-g flex flex-col gap-3 p-4">
            <Theme.Label>Wallet address</Theme.Label>
            <Theme.Input
              value={inputAddress}
              type="email"
              onChange={inputAddressChange}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                event.key === "Enter" && confirmCustomWalletHandler()
              }
              placeholder="Enter Wallet Address"
              className="bg-brand-100 h-[44px]"
            />
            {!ss58Valid && inputAddress && (
              <span className="pl-1 text-red-500 opacity-100">
                {UIMessage.MSG_ERR_SS58_FORMAT}
              </span>
            )}
          </div>
          <div className="flex flex-row gap-4">
            <Theme.Button
              color="brand"
              fullWidth
              className="h-11 max-w-[200px]"
              loading={loading}
              disabled={!ss58Valid}
              iconLeft={<Theme.Icon.CheckCircle size={20} />}
              onClick={() => confirmCustomWalletHandler()}
            >
              Confirm
            </Theme.Button>
            <Theme.Button
              color="brand"
              fullWidth
              className="h-11 max-w-[200px]"
              iconLeft={<Theme.Icon.X size={20} />}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Theme.Button>
          </div>
        </div>
      </div>
    </>
  );
}
