import { FirebaseError } from "@firebase/util";

import { UserInfo } from "~/types";

import {
  auth,
  ErrorCodes,
  FireBase,
  generateSubnetApiKey,
  getSubnetApikey,
  logStartTime,
} from "../Firebase/Firebase";

export const AuthApi = {
  signIn: async (options: { email: string; password: string }) => {
    const email =
      options?.email ??
      (() => {
        throw new Error("Email is mandatory");
      })();
    const password =
      options?.password ??
      (() => {
        throw new Error("Password is mandatory");
      })();
    let token: string | undefined;

    await auth.signInWithEmailAndPassword(email, password).catch((error) => {
      error instanceof FirebaseError &&
      error?.code === ErrorCodes.AUTH_INVALID_CREDINTIAL
        ? (() => {
            throw new Error("Invalid Credentials");
          })()
        : ((error) => {
            throw error;
          })();
    });

    if (!auth.currentUser?.emailVerified) throw new Error("Email not verified");
    await auth.currentUser?.getIdToken(false).then((idToken) => {
      token = idToken;
    });

    const subnetApiKey = await getSubnetApikey();

    const userInfo: UserInfo = {
      email: auth?.currentUser?.email,
      displayName: auth?.currentUser?.displayName,
      phoneNumber: auth?.currentUser?.phoneNumber,
      photoURL: auth?.currentUser?.photoURL,
      providerId: auth?.currentUser?.providerId,
      uid: auth?.currentUser?.uid,
      idToken: token,
      // refreshToken?: auth?.currentUser?.displayName,
      subnetApitoken: subnetApiKey,
      emailVerified: auth?.currentUser?.emailVerified,
    };
    return userInfo;
  },

  /**
   * Sign up using firebase API with user information from UI
   * @param options
   * @returns
   */
  signUp: async (options: { user: UserInfo }) => {
    const user = options?.user;
    const email =
      user?.email ??
      (() => {
        throw new Error("Email is mandatory");
      })();
    const password =
      user?.password ??
      (() => {
        throw new Error("Password is mandatory");
      })();
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredintials) => {
        if (userCredintials.user) {
          userCredintials.user.updateProfile({ ...user });

          //Send email verification
          FireBase.sendEmailVerification(userCredintials.user, null);

          //Generate subnet API key.
          logStartTime("generateSubnetApiKey");
          await generateSubnetApiKey();
          logStartTime("generateSubnetApiKey");
        }
      })
      .catch((error) => {
        error instanceof FirebaseError &&
        error?.code === ErrorCodes.EMAIL_ALREADY_IN_USE
          ? (() => {
              throw new Error("Email-already-in-use");
            })()
          : ((error) => {
              throw error;
            })();
        throw error;
      });

    const userInfo: UserInfo = { ...auth.currentUser, password: "" };
    return userInfo;
  },

  /**
   * Send reset password link to user
   * @param options
   */
  resetPassword: async (options: { email: string }) => {
    const email =
      options?.email ??
      (() => {
        throw new Error("Email is mandatory");
      })();
    await FireBase.sendPasswordResetEmail(auth, email).catch((error) => {
      error instanceof FirebaseError && error?.code === ErrorCodes.USER_NOT_FOUND
        ? (() => {
            throw new Error("User not found");
          })()
        : ((error) => {
            throw error;
          })();
    });
  },

  /**
   * Sign out firebase user on logout click clears auth object on client side SDK
   */
  signOut: async () => {
    auth.signOut();
  },
};
