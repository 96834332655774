import { Generation } from "~/Generation";

import { Buttons } from "./Buttons";

export function Display({ id }: { id: ID }) {
  const { setInput, importInit, input } = Generation.Image.Input.use(id);
  const setInitialImage = Generation.Image.Session.useSetInitialImage();
  const setLastStrength = Generation.Image.Session.useSetLastStrength();
  const aspectRatio = useMemo(
    () => (input?.width && input?.height ? input.width / input.height : 1),
    [input],
  );
  if (!input || !input.init) return null;
  return (
    <div>
      {(input?.init as any)?.src && (
        <div
          className="relative h-full"
          style={{
            aspectRatio,
            background: "hsl(0, 0%, 50%)",
            ...({
              "--blur": `${
                Math.pow(1 - input.strength, 8) * 50 + (1 - input.strength) * 3
              }px`,
            } as React.CSSProperties),
          }}
          css={css`
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              backdrop-filter: blur(var(--blur));
            }
          `}
        >
          <img
            src={"base64" in input.init ? input.init?.base64 : input.init?.src}
            id="init-img"
            className="absolute h-full w-full object-cover"
            style={{ aspectRatio }}
          />
        </div>
      )}
    </div>
  );
}

export declare namespace UploadImage {
  export { Buttons, Display };
}

export namespace UploadImage {
  UploadImage.Buttons = Buttons;
  UploadImage.Display = Display;
}
