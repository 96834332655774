import * as ReactQuery from "@tanstack/react-query";

import { useCallback, useEffect, useState } from "react";
import { HvpAPI } from "~/api/HvpAPI";
import { App } from "~/App";

import { Theme } from "~/Theme";
import { UIMessage } from "~/UIMessage";

import { Round } from "./Round";

import UserInfo from "./UserInfo";

export function Validation() {
  const [rounds, setRounds] = useState<{
    currentRound: Round.RoundItem | null;
    nextRound: Round.RoundItem | null;
  }>({ currentRound: null, nextRound: null });

  const token = App.getToken();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(false);
  const isMobileDevice = Theme.useIsMobileDevice();

  const [limitExceeded, setLimitExceeded] = useState(false);

  const {
    refetch,
    isLoading,
    isFetching,
    data: roundData,
  } = ReactQuery.useQuery({
    queryKey: ["Validation.Round"],
    queryFn: () => HvpAPI.getRounds({ accessToken: token! }),
    staleTime: 0,
    cacheTime: 0,
    enabled: !!token,
  });

  useEffect(() => {
    if (roundData && roundData.length > 0) {
      const roundDataWithVotesAllowed = {
        ...roundData[0],
        votes_allowed: roundData[0].votes_allowed ?? 3,
      };

      if (!rounds.currentRound) {
        setRounds((prev) => ({
          ...prev,
          currentRound: roundDataWithVotesAllowed,
          nextRound: null,
        }));
        refetch();
      } else {
        setRounds((prev) => ({
          ...prev,
          nextRound: roundDataWithVotesAllowed,
        }));
      }
    }
  }, [roundData]);

  const moveToNextRound = useCallback(() => {
    setRounds((prevRounds) => {
      if (prevRounds.nextRound) {
        return {
          nextRound: null,
          currentRound: prevRounds.nextRound,
        };
      } else {
        return {
          ...prevRounds,
          currentRound: null,
        };
      }
    });
    refetch();
  }, [refetch]);

  const submitVotesLocal = async (selected: Round.SelectedItems) => {
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    setSubmitting(true);
    try {
      const submitRound = {
        ...rounds.currentRound,
        computes: rounds.currentRound?.computes?.filter(
          (item) => item.id && Object.keys(selected).includes(item.id),
        ),
      };
      moveToNextRound();
      token && (await HvpAPI.submitVotes({ ...submitRound, accessToken: token }));
    } catch (error: any) {
      console.log("trace", "error submitting votes", error);
      if (error?.response?.status === 429) {
        setLimitExceeded(true);
        console.error("Rate limit exceeded. Please try again later.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  //Refetch round on expiry
  const restartVote = async () => {
    setExpired(false);
    setRounds({ currentRound: null, nextRound: null });
    refetch();
  };

  const shouldShowFade =
    (!rounds.currentRound && (isFetching || submitting)) || expired || limitExceeded;
  const isCurrentRoundPresent =
    rounds.currentRound &&
    rounds.currentRound.id !== "-1" &&
    rounds.currentRound?.computes &&
    rounds.currentRound?.computes.length > 0;

  return (
    <>
      <div
        className={classes(
          "bg-brand-100 relative z-[1] flex grow flex-col items-center overflow-hidden rounded-lg py-4",
          shouldShowFade &&
            "cursor-disable pointer-events-none cursor-not-allowed opacity-50",
          "mb-2 min-h-[150px]",
        )}
      >
        {!rounds.currentRound && isLoading && (
          <Theme.Loading.Spinner className="absolute top-[50%] z-[2] h-16 w-16 self-center" />
        )}

        {!rounds.currentRound && (isFetching || submitting) && !isLoading && (
          <Theme.Loading.Custom
            className="absolute top-[50%] z-[2] h-16 w-16 self-center"
            type="bars"
          />
        )}

        {limitExceeded && (
          <div className="flex flex-col gap-2">
            <p className="rounded p-4 text-2xl font-bold text-white">
              {`You've voted on everything for today`}
            </p>
          </div>
        )}

        {!limitExceeded && isCurrentRoundPresent && rounds.currentRound && (
          <Validation.Round
            key={rounds.currentRound.id}
            round={rounds.currentRound}
            submitVoteHandler={submitVotesLocal}
            loading={!rounds.currentRound && submitting}
            timeOutHandler={() => !expired && setExpired(true)}
          />
        )}

        <div className="hidden">
          {rounds.nextRound && (
            <Validation.Round
              key={rounds.nextRound.id}
              round={rounds.nextRound}
              submitVoteHandler={submitVotesLocal}
              loading={!rounds.nextRound && submitting}
              timeOutHandler={() => !expired && setExpired(true)}
            />
          )}
        </div>
      </div>

      {expired && (
        <div
          className={classes(
            "absolute left-[40%] top-[30%] z-[2] flex flex-col gap-2",
            isMobileDevice && "left-[20%] top-[40%] break-all",
          )}
        >
          <Theme.Label
            className={classes(
              "bg-brand-50 rounded p-4 text-2xl text-white",
              isMobileDevice && "text-xl",
            )}
          >
            {UIMessage.ERR_ROUND_EXPIRED}
          </Theme.Label>
          <Theme.Button
            color="brand"
            iconLeft={<Theme.Icon.RefreshClockwise size={32} />}
            className="self-center"
            onClick={() => restartVote()}
          >
            Reload
          </Theme.Button>
        </div>
      )}

      <UserInfo />
    </>
  );
}

export declare namespace Validation {
  export { Round };
}

export namespace Validation {
  Validation.Round = Round;
}
