import { SnackbarProvider } from "notistack";
import React from "react";

import { Environment } from "~/Environment";
import { Remote } from "~/Remote";
import { Router } from "~/App/Router";

export function Providers({ children }: React.PropsWithChildren) {
  return (
    <Environment.Provider>
      <Router.Provider>
        <SnackbarProvider>
          <Remote.Provider>{children}</Remote.Provider>
        </SnackbarProvider>
      </Router.Provider>
    </Environment.Provider>
  );
}
