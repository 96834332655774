import { useState } from "react";
import { AuthApi } from "~/api/AuthApi";
import { User } from "~/components/auth/Login";
import { UserInfo } from "~/types";

interface LoginCredentials {
  email: string;
  password: string;
  remember?: boolean;
}

interface UseAuthReturn {
  login: (credentials: LoginCredentials) => Promise<{
    success: boolean;
    error?: string;
    data?: UserInfo;
  }>;
  loading: boolean;
  logout: () => void;
  updateUser: (userInfo: Partial<UserInfo>) => void;
  signUp: (userInfo: UserInfo) => Promise<{
    success: boolean;
    error?: string;
    data?: UserInfo;
  }>;
}

export const useAuth = (): UseAuthReturn => {
  const [loading, setLoading] = useState(false);

  const login = async ({ email, password, remember }: LoginCredentials) => {
    try {
      setLoading(true);
      const response = await AuthApi.signIn({
        email,
        password,
      });

      if (response) {
        User.add({ ...response, remember });
        return { success: true, data: response };
      }
      return { success: false, error: "Invalid response from server" };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error: error instanceof Error ? error.message : "Invalid Credentials",
      };
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    AuthApi.signOut();
    User.remove();
  };

  const updateUser = (userInfo: Partial<UserInfo>) => {
    User.update(userInfo);
  };

  const signUp = async (userInfo: UserInfo) => {
    try {
      setLoading(true);
      const response = await AuthApi.signUp({ user: userInfo });
      return { success: true, data: response };
    } catch (error) {
      return {
        success: false,
        error: error instanceof Error ? error.message : "Invalid Credentials",
      };
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    loading,
    logout,
    updateUser,
    signUp,
  };
};
