import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useClickAway } from "react-use";
import { AuthApi } from "~/api/AuthApi";
import { IdpAPI } from "~/api/IdpAPI";
import { App } from "~/App";
import { Router } from "~/App/Router";
import { User } from "~/components/auth/Login";
import { Generation } from "~/Generation";
import { Client } from "~/Remote/Client";
import { Theme } from "~/Theme";
import { UIMessage } from "~/UIMessage";
import { Verification } from "~/Verification";
import { Wallet } from "~/Wallet";

function HumanValidationButton({ onClick }: { onClick: () => void }) {
  return (
    <Theme.Button
      className="relative h-10 p-4"
      iconLeft={
        <Theme.Icon.CheckCircle className="relative h-4 w-4 shrink-0 overflow-hidden" />
      }
      label="Vote on images, get paid"
      labelPlacement="bottom"
      color="ternary"
      onClick={onClick}
    >
      Human Validation
    </Theme.Button>
  );
}

function ImageGenerationButton({ onClick }: { onClick: () => void }) {
  return (
    <Theme.Button
      className="relative h-10 p-4"
      iconLeft={<Theme.Icon.Edit className="h-4 w-4" />}
      color="ternary"
      label="Generate image"
      labelPlacement="bottom"
      onClick={onClick}
    >
      Image Generation
    </Theme.Button>
  );
}

function VerificationButton({
  status,
  loading,
  onClick,
  isMobileDevice,
}: {
  status: Verification.IdpStatus;
  loading: boolean;
  onClick: () => void;
  isMobileDevice: boolean;
}) {
  return (
    <Theme.Button
      className={classes("relative h-10 p-4")}
      iconLeft={<Theme.Icon.ScanFaceIcon className="h-4 w-4" />}
      color={
        status == Verification.IdpStatus.FAILURE
          ? "red"
          : status == Verification.IdpStatus.PENDING
          ? "yellow"
          : "brand"
      }
      label={Verification.getToolTip(status)}
      labelPlacement="bottom"
      loading={loading}
      onClick={onClick}
    >
      {!isMobileDevice && Verification.getLabel(status)}
    </Theme.Button>
  );
}

function NavigationButton({ isGenerate }: { isGenerate: boolean }) {
  const navigate = Router.useNavigate();
  const status = Verification.use();
  const token = App.getToken();
  const isMobileDevice = Theme.useIsMobileDevice();
  const [loading, setLoading] = useState(false);
  const [pollingTime, setPollingTime] = useState<number>(0);
  const delay = 15000; //15 sec
  const timeOut = 20 * 60000; //20 mins

  //Polling function for verification
  const pollForIdpStatus = async () => {
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    //Check verificatin status with IDP
    const status = await IdpAPI.getVerificationStatus({ accessToken: token });
    //Stop polling if status is null
    if (!status) return;
    const currentIdpStatus = Verification.Idp.getIdpStatusFromString(status);
    Verification.setStatus({ status: currentIdpStatus });
    //Continue polling if status is pending and until timeout
    if (currentIdpStatus == Verification.IdpStatus.PENDING && pollingTime < timeOut) {
      setPollingTime(pollingTime + delay);
    } else {
      setLoading(false);
    }
  };

  const onClickVerify = () => {
    setLoading(true);
    Verification.openIdpTab(token);
    const intervalId = setInterval(() => {
      pollForIdpStatus();
      if (status != Verification.IdpStatus.PENDING || pollingTime >= timeOut) {
        clearInterval(intervalId);
      }
    }, delay);
  };

  if (isGenerate) {
    return <HumanValidationButton onClick={() => navigate("/validate")} />;
  }
  return (
    <>
      <ImageGenerationButton onClick={() => navigate("/generate")} />
      {status != Verification.IdpStatus.SUCCESS && (
        <VerificationButton
          status={status}
          loading={loading}
          onClick={onClickVerify}
          isMobileDevice={isMobileDevice}
        />
      )}
    </>
  );
}

export const Right = () => {
  const navigate = Router.useNavigate();
  const path = useLocation().pathname;
  const isGenerate = useMemo(() => path.startsWith("/generate"), [path]);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const tabRef = useRef<HTMLDivElement>(null);
  useClickAway(tabRef, () => setShowProfileMenu(!showProfileMenu));
  const isMobileDevice = Theme.useIsMobileDevice();

  const logOut = async () => {
    User.remove();
    localStorage.removeItem("access_token_expiry");
    Generation.Image.Output.clearAll();
    Generation.Image.Inputs.set({});
    //TODO:clear whole store
    Wallet.add({ address: "", mnemonic: "" });
    Verification.setStatus({ status: Verification.IdpStatus.NONE });
    Client.get().clear();
    //Firebase signout
    await AuthApi.signOut();
    navigate("/login");
  };

  const menu = useMemo(() => {
    return (
      <>
        {showProfileMenu && (
          <div
            className={classes(
              "bg-brand-100 absolute -bottom-[60px] z-[100] flex min-w-[100px] shrink flex-col items-center",
            )}
            ref={tabRef}
          >
            <Theme.Button
              color="ternary"
              fullWidth
              className={classes(
                "hover:font-semibold hover:underline",
                !isMobileDevice && "p-2",
              )}
            >
              <Link to="/profile">Profile</Link>
            </Theme.Button>

            <Theme.Button
              color="ternary"
              fullWidth
              className={classes(
                "hover:font-semibold hover:underline",
                !isMobileDevice && "p-2",
              )}
              onClick={logOut}
            >
              Logout
            </Theme.Button>
          </div>
        )}
      </>
    );
  }, [path, showProfileMenu]);

  return (
    <div className="flex flex-row items-center justify-start gap-4 self-stretch p-1 text-sm">
      <NavigationButton isGenerate={isGenerate} />
      <div
        className="border-brand-200 hover:bg-brand-25 box-border flex flex-col items-center gap-3 rounded-lg border border-solid p-2 text-sm"
        onClick={() => {
          setShowProfileMenu(!showProfileMenu);
        }}
      >
        {Verification.IdpStatus.SUCCESS == Verification.use() ? (
          <Theme.Tooltip placement="right" content="Verified">
            <Theme.Icon.UserCheck className="cursor-pointer" size={20} />
          </Theme.Tooltip>
        ) : (
          <Theme.Tooltip placement="right" content="Not verified">
            <Theme.Icon.User className="cursor-pointer" size={20} />
          </Theme.Tooltip>
        )}

        {menu}
      </div>
    </div>
  );
};
