import React, { useRef, useState } from "react";
import { User } from "~/components/auth/Login";
import { Theme } from "~/Theme";
import { Wallet } from "~/Wallet";

import { useAvatarUpdate } from "./hooks/useAvatarUpdate";
import { useUsernameUpdate } from "./hooks/useUsernameUpdate";

export function Profile() {
  const user = User.use();
  const [editing, setEditing] = useState(false);
  const [username, setUsername] = useState(user?.displayName || "");
  const [profilePicture, setProfilePicture] = useState(user?.photoURL || "");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { updateUserAvatar, isLoading: isAvatarLoading } = useAvatarUpdate({
    onSuccess: () => {
      setEditing(false);
    },
  });

  const { updateUserUsername, isLoading: isUsernameLoading } = useUsernameUpdate({
    onSuccess: () => {
      setEditing(false);
    },
  });

  const handleSave = async () => {
    try {
      if (username !== user?.displayName) {
        await updateUserUsername(username);
      }

      if (fileInputRef.current?.files?.[0]) {
        await updateUserAvatar(fileInputRef.current.files[0]);
      } else {
        setEditing(false);
      }
    } catch (error) {
      // Error handling is now managed in the hooks
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="bg-brand-100 flex flex-col items-center justify-center rounded-lg p-2 md:p-8">
      <div className="mb-4">
        {editing ? (
          <label className="relative cursor-pointer">
            <img
              src={profilePicture || "default-avatar.webp"}
              alt="Profile"
              className="h-32 w-32 rounded-full object-cover"
            />
            <div className="bg-brand-200 absolute bottom-0 right-0 rounded-full p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </label>
        ) : (
          <img
            src={user?.photoURL || "default-avatar.webp"}
            alt="Profile"
            className="h-32 w-32 rounded-full object-cover"
          />
        )}
      </div>
      {editing ? (
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="bg-brand-200 mb-4 rounded p-2 text-white"
        />
      ) : (
        <h2 className="mb-4 text-2xl font-bold">
          {user?.displayName || (
            <span className="text-xs text-gray-400">
              (set your username by clicking the edit button)
            </span>
          )}
        </h2>
      )}
      <p className="mb-4 text-gray-400">{user?.email}</p>
      {editing ? (
        <div className="flex gap-2">
          <Theme.Button
            color="brand"
            onClick={handleSave}
            loading={isAvatarLoading || isUsernameLoading}
          >
            Save
          </Theme.Button>
          <Theme.Button color="ternary" onClick={() => setEditing(false)}>
            Cancel
          </Theme.Button>
        </div>
      ) : (
        <Theme.Button color="brand" onClick={() => setEditing(true)}>
          Edit Profile
        </Theme.Button>
      )}
      <div className="w-full">
        <Wallet />
      </div>
    </div>
  );
}
