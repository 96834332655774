import { useState } from "react";
import { IdpAPI } from "~/api/IdpAPI";
import { App } from "~/App";
import { Generation } from "~/Generation";
import { User } from "~/components/auth/Login";

interface UseUsernameUpdateOptions {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useUsernameUpdate = (options?: UseUsernameUpdateOptions) => {
  const token = App.getToken();
  const showSnackbar = Generation.Image.Exception.Snackbar.showMessage();
  const [isLoading, setIsLoading] = useState(false);

  const updateUserUsername = async (username: string) => {
    if (!token) {
      showSnackbar("No token found", "center", "top", "error");
      return;
    }

    try {
      setIsLoading(true);
      await IdpAPI.updateUsername({ username, accessToken: token });
      User.update({ displayName: username });
      options?.onSuccess?.();
      showSnackbar("Username updated successfully", "center", "top", "success");
    } catch (error: any) {
      if (error.response?.status === 409) {
        showSnackbar("Username already taken", "center", "top", "error");
      } else {
        showSnackbar("Failed to update username", "center", "top", "error");
      }
      options?.onError?.(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateUserUsername,
    isLoading,
    isEnabled: !!token,
  };
};
