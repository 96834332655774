import { IdpAPI } from "~/api/IdpAPI";
import { GlobalState } from "~/GlobalState";

import { UIMessage } from "~/UIMessage";

import { State } from "./State";
export namespace Verification {
  export declare type IdpData = {
    status?: IdpStatus;
  };

  export const openIdpTab = async (token?: string | null): Promise<void> => {
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    const url = await IdpAPI.verifyUser({ accessToken: token });
    window?.open(url, "_blank")?.focus();
  };

  export const getLabel = (status: IdpStatus): string => {
    switch (status) {
      case IdpStatus.PENDING:
        return "Pending";

      case IdpStatus.FAILURE:
        return "Failed";
    }
    return "Proof of Humanity";
  };

  export const getToolTip = (status: IdpStatus): string => {
    switch (status) {
      case IdpStatus.PENDING:
        return "Your verification is pending";

      case IdpStatus.FAILURE:
        return "Verification failed try again";
    }
    return "Verify your identity to get paid";
  };

  export enum IdpStatus {
    NONE = "NONE",
    PENDING = "PENDING",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
  }

  export namespace Idp {
    export const getIdpStatusFromString = (value: string): IdpStatus => {
      const arr = Object.values(IdpStatus).filter((item: string) => item == value);
      if (arr.length == 0) throw new Error(UIMessage.ERR_IDP_STATUS_NOT_FOUND);
      return arr[0];
    };
  }

  export const setStatus = (value: IdpData) => {
    State.get().set(value);
  };

  export const use = (): IdpStatus =>
    State.use(
      ({ idpData }) => (idpData.status ? idpData.status : IdpStatus.NONE),
      GlobalState.shallow,
    );

  export const updateIdpState = async (token: string) => {
    //Get verification status of logged in user
    const status = await IdpAPI.getVerificationStatus({ accessToken: token });

    Verification.setStatus({
      status: Verification.Idp.getIdpStatusFromString(status),
    });
  };
}
