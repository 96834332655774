import { GlobalState } from "~/GlobalState";
import { Theme } from "~/Theme";
import { UIMessage } from "~/UIMessage";

export type Count = number;
export namespace Count {
  export function Button() {
    const [count, setCount] = Count.use();
    const max = 12;
    const min = 1;

    return (
      <Theme.Tooltip content={UIMessage.IMG_COUNT_TOOLTIP} placement="top">
        <div
          className="bg-brand-200 font-inter flex h-[44px] grow flex-row items-center justify-between space-x-3 rounded
        p-2 text-base"
        >
          <div className="flex flex-row space-x-1">
            <img
              className="relative h-6 shrink-0 overflow-hidden"
              alt=""
              src="ImageCounter.svg"
            />
            <div className="relative">Image count</div>
          </div>
          <div
            className={classes("cursor-not-allowed", count > min && "cursor-pointer")}
            onClick={() => {
              if (count > min) setCount(count - 1);
            }}
          >
            -
          </div>
          <div>{count}</div>
          <div
            className={classes("cursor-not-allowed", count < max && "cursor-pointer")}
            onClick={() => {
              if (count < max) setCount(count + 1);
            }}
          >
            +
          </div>
        </div>
      </Theme.Tooltip>
    );
  }
}

export namespace Count {
  export const preset = () => 4 as const;

  export const get = (): number => store.getState().count;
  export const set = (count: number) => store.getState().setCount(count);
  export const use = () =>
    store(({ count, setCount }) => [count, setCount] as const, GlobalState.shallow);
}

const store = GlobalState.create<{
  count: number;
  setCount: (count: number) => void;
}>((set) => ({
  count: Count.preset(),
  setCount: (count) => set({ count }),
}));
