import { Wallet } from "@stability/alchemy-studio-plugin";
import { AxiosError } from "axios";
import { GlobalAPI } from "../GlobalAPI";

export namespace WalletAPI {
  const ERR_WALLET_NOT_FOUND = "WALLET_NOT_FOUND";
  //Function to get wallet info of existing user
  export const getWalletInfo = async (options: {
    accessToken: string;
  }): Promise<Wallet> => {
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    try {
      const response = await GlobalAPI.fetchData(`/users/${user_id}/wallet`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      return { ...response.data };
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.code == ERR_WALLET_NOT_FOUND
      ) {
        //Ignore no wallet error
        return {};
      }
      throw error;
    }
  };

  //Function to create wallet for existing user
  export const createWallet = async (options: {
    accessToken: string;
  }): Promise<Wallet> => {
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    const { data } = await GlobalAPI.fetchData(`/users/${user_id}/wallet`, {
      method: "POST",
      headers: { Authorization: `Bearer ${access_token}` },
    });
    return { ...data };
  };

  //Function to update wallet for existing user
  export const updateWallet = async (
    options: Wallet & { accessToken: string },
  ): Promise<Wallet> => {
    const address = options?.address;
    if (!address) throw Error("Address is mandatory for custom wallet");
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    const { data } = await GlobalAPI.fetchData(`/users/${user_id}/wallet`, {
      method: "PUT",
      body: { address },
      headers: { Authorization: `Bearer ${access_token}` },
    });
    return { ...data };
  };
}
