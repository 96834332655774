import React from "react";
export const RandomImageRow = ({
  images,
  isMobile = false,
}: {
  images: string[];
  isMobile?: boolean;
}) => {
  return (
    <div className="flex flex-row justify-center gap-3">
      {images.map((url, index) => (
        <img
          className={`rounded-sm transition-all duration-300 hover:scale-150 hover:cursor-zoom-in ${
            isMobile ? "h-36 w-36" : "h-[241px] w-[241px]"
          }`}
          alt=""
          src={url}
          key={index}
        />
      ))}
    </div>
  );
};
