import { Center } from "~/App/TopBar/Center";
import { Left } from "~/App/TopBar/Left";
import { Router } from "~/App/Router";
import { Theme } from "~/Theme";

import { RandomImage } from "../RandomImage";

import { RandomImageRow } from "../RandomImageRow";

export function MobileLandingPage() {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const navigate = Router.useNavigate();

  return (
    <>
      <div
        className="text-whitesmoke font-inter bg-brand-100 absolute left-0 top-0 flex h-screen w-screen flex-col
  items-center overflow-y-auto overflow-x-hidden"
      >
        <div className="h-18 text-whitesmoke font-inter relative box-border flex w-full flex-row items-center justify-between px-[20px] py-2 text-left text-lg 2xl:max-w-[1348px]">
          <Left />
          <Center />
          <div className="flex flex-row items-center justify-start gap-4 self-stretch p-1 text-sm">
            <Theme.Button
              color="ternary"
              className="h-11 rounded-lg border text-sm font-semibold"
              label="Existing user"
              labelPlacement="bottom"
              onClick={() => navigate("/login")}
            >
              {" "}
              Log in
            </Theme.Button>
            <Theme.Button
              color="white"
              className="text-brand-100 h-11 rounded-lg border text-sm font-semibold"
              label="New user"
              labelPlacement="bottom"
              onClick={() => navigate("/signup")}
            >
              {" "}
              Sign up
            </Theme.Button>
          </div>
        </div>
        <div className="flex w-full flex-col gap-5 px-4">
          <div className="font-inter text-whitesmoke relative inline-block w-full text-left text-3xl leading-[44px]">
            <p className="m-0">AI Image Generator:</p>
            <p className="m-0 flex flex-row">
              <span>{`Turn `}</span>
              <span className="text-brand-1000 relative  px-3">text to images</span>
              <span className="text-whitesmoke"> in</span>
            </p>
            <p className="m-0">seconds</p>
          </div>
          <div className="text-whitemoke relative text-base opacity-75">
            Tensor Alchemy is an AI system that can create realistic images and art from
            a description in natural language.
          </div>
          <div className="bg-brand-200 font-inter border-lightgray flex h-11 flex-row items-center justify-center gap-2 rounded border px-4 py-2">
            <div className="animate-text from-brand-1100  to-brand-1100  bg-gradient-to-r via-fuchsia-300 bg-clip-text text-sm text-transparent">
              Join our Discord server!
            </div>
            <Theme.Button
              color="white"
              className="text-brand-100 h-7 rounded border text-sm font-semibold"
              label="Join discord bot"
              labelPlacement="bottom"
              iconRight={<Theme.Icon.ArrowRight size={16} className="stroke-2" />}
              onClick={() => {
                openInNewTab("https://discord.gg/tensoralchemy");
              }}
            >
              Join
            </Theme.Button>
          </div>

          <Theme.Button
            color="brand"
            fullWidth
            className="h-12 rounded font-semibold"
            icon={
              <img
                className=" flex h-5 w-5"
                alt="AlchemyStudio"
                src="stars-light-sparkle.svg"
              />
            }
            onClick={() => {
              navigate("/signup");
            }}
            labelPlacement="bottom"
          >
            Get started
          </Theme.Button>
        </div>
        <div className="flex w-[550px] justify-center gap-2">
          <img
            src="main_asset_mobile.svg"
            className="pt-18 self-center object-cover  pl-28"
          />
        </div>
        <div className="flex w-max flex-col justify-center gap-1">
          <RandomImageRow images={RandomImage.firstRow} isMobile />
          <RandomImageRow images={RandomImage.secondRow} isMobile />
        </div>
      </div>
    </>
  );
}
