import React from "react";
import { Link } from "react-router-dom";
import { Router } from "~/App/Router";
import { TopBar } from "~/App/TopBar";
import { LeftBanner } from "~/components/auth/Login/LeftBanner";
import { Routes } from "~/constants/Routes";
import { Generation } from "~/Generation";
import { Theme } from "~/Theme";
import { UserInfo } from "~/types";

import { useAuth } from "../hooks/useAuth";

export function Signup() {
  const [email, setEmail] = useState("");
  const navigate = Router.useNavigate();
  const { signUp, loading } = useAuth();
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const showSnackBar = Generation.Image.Exception.Snackbar.showMessage();
  const deviceType = Theme.useDevice();
  const isMobileDevice = Theme.useIsMobileDevice();

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    createAccount();
  };

  const createAccount = async () => {
    if (!signUp) throw new Error("Plugin not found");
    const userData: UserInfo = {
      email: email,
      password: password,
    };
    try {
      if (!email) throw new Error("Email is mandatory");
      if (!password) throw new Error("Password is mandatory");
      const response = await signUp(userData);
      if (response) {
        response?.data?.emailVerified && navigate(Routes.GENERATE);
      }

      showSnackBar("Email verification link sent", "center", "top", "success");
      navigate(Routes.LOGIN);
    } catch (error) {
      console.log(error);
      const exception = Generation.Image.Exception.create(error);
      showSnackBar(exception, "center", "top", "error");
    }
  };

  const leftBanner = useMemo(() => {
    return deviceType == "large" && <LeftBanner />;
  }, [deviceType]);

  return (
    <>
      <div className="bg-brand-50 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden">
        <TopBar />
        <div className="bg-brand-50 z-0 flex flex-row justify-between overflow-hidden md:max-w-[1348px]">
          {leftBanner}
          <div className="flex flex-col gap-2 p-8">
            <div className="flex flex-row justify-center gap-4">
              {!isMobileDevice && <Theme.Logo className="h-10 w-10 rounded" />}
              <div className="font-inter text-whitesmoke xxsm:text-2xl self-center text-left">
                Welcome to Alchemy Studio
              </div>
            </div>
            <div className="bg-brand-100 relative flex w-full flex-col gap-6 rounded-lg p-2 p-4">
              <div className="text-whitesmoke flex flex-col gap-5">
                <form className="flex flex-col gap-5" onSubmit={submit}>
                  <Theme.Label>Email</Theme.Label>
                  <Theme.Input
                    value={email}
                    type="email"
                    onChange={setEmail}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                      event.key === "Enter" && submit
                    }
                    placeholder="Email"
                    className="h-[44px]"
                  />
                  <Theme.Label>Password</Theme.Label>
                  <div className="flex flex-row">
                    <Theme.Input
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={setPassword}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                        event.key === "Enter" && submit
                      }
                      className="h-[44px]"
                      placeholder="••••••••"
                    />
                    <div
                      className="dark:placeholder:text-muted-white bg-brand-50 flex h-[44px] text-black shadow-sm focus:border-transparent focus:outline-none dark:border-none dark:text-white"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <Theme.Icon.Eye className="self-center opacity-50" />
                      ) : (
                        <Theme.Icon.EyeOff className="self-center opacity-50" />
                      )}
                    </div>
                  </div>

                  <Theme.Button
                    color="brand"
                    loading={loading}
                    label="Sign In"
                    className="font-inter text-whitesmoke h-[44px] w-full text-base font-medium"
                  >
                    Continue
                  </Theme.Button>
                </form>

                <div className="text-center text-sm font-semibold text-slate-500">
                  Login to TensorAlchemy{" "}
                  <Link
                    className="text-sm text-[#009EFF] hover:underline hover:underline-offset-4"
                    to="/login"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
            <div className="relative w-[374px] text-center text-sm text-slate-500">
              By signing up, you agree to our Terms of Service and Privacy Policy
              Agreement.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
