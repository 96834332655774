import * as ReactQuery from "@tanstack/react-query";
import { Generation } from "~/Generation";
import { GlobalState } from "~/GlobalState";

import { Theme } from "~/Theme";

import { Delete } from "./Delete";
import { Download } from "./Download";
import { Feedback } from "./Feedback";
import { Modal } from "./Modal";
import { Execute } from "./Query/Execute";
import { Scroll } from "./Scroll";
import { State } from "./State";

export type Images = Generation.Image[];

export function Images({ className }: Images.Props) {
  const outputs = Generation.Image.Outputs.history();
  const paginationData = Generation.Image.Outputs.getPaginationData();
  const isMobileDevice = Theme.useIsMobileDevice();

  const limit = 8;
  const marginPagesDisplayed = isMobileDevice ? 1 : 2;
  const emptyItems = [1, 2, 3, 4];
  const [page, setPage] = useState(1);
  const [pageList, setPageList] = useState([]);
  const initialCursor = {
    limit: limit,
    startDate: new Date(),
    stopDate: new Date(),
    page: 1,
    forward: true,
  };
  const [options, setOptions] = useState(initialCursor);
  const parentRef = useRef<HTMLDivElement>(null);

  const { isLoading, isError, error, data, isFetching, isPreviousData } =
    ReactQuery.useQuery({
      enabled: true,
      queryKey: ["Generation.Images.Query.use", options],
      queryFn: Execute.use(),

      staleTime: 0,
      cacheTime: 0,
    });

  useMemo(() => {
    const fetchTotalCount = async () => {
      const pageList = [];
      const count = paginationData.pages ?? 0;
      for (let i = 1; i <= count; i++) {
        pageList.push(i);
      }
      setPageList(pageList as never[]);
    };
    fetchTotalCount();
  }, [paginationData.pages]);

  const totalPages = useMemo(
    () =>
      pageList.map((index: number) => {
        if (
          index > marginPagesDisplayed &&
          index <= pageList.length - marginPagesDisplayed &&
          (index > page + 1 || index < page - 1)
        ) {
          return (index == marginPagesDisplayed + 1 && index < page - 1) ||
            (index == pageList.length - marginPagesDisplayed && index > page + 1) ? (
            <div>...</div>
          ) : null;
        }
        return (
          <div
            className={classes(
              page == index
                ? "font-inter bg-brand-25 h-6 w-6 whitespace-nowrap rounded-md text-center text-white"
                : "opacity-muted cursor-not-allowed",
            )}
          >
            {index}
          </div>
        );
      }),
    [pageList],
  );

  const noImagesDiv = useMemo(
    () =>
      emptyItems.map((item, index) => {
        return (
          <div key={index}>
            <Generation.Image preserveAspectRatio placeholder={true} />
          </div>
        );
      }),
    [emptyItems, outputs],
  );

  const nextClick = () => {
    setPage((old: number) => old + 1);
    setOptions({
      ...options,
      page: page + 1,
      forward: true,
      startDate: outputs?.length == 0 ? new Date() : (outputs[0].completedAt as Date),
      stopDate:
        outputs?.length == 0
          ? new Date()
          : (outputs[outputs.length - 1].completedAt as Date),
    });
    Generation.Image.Create.Latest.set(undefined);
  };

  const previousClick = () => {
    setPage((old: number) => Math.max(old - 1, 0));
    setOptions({
      ...options,
      page: page - 1,
      forward: false,
      startDate: outputs?.length == 0 ? new Date() : (outputs[0].completedAt as Date),
      stopDate:
        outputs?.length == 0
          ? new Date()
          : (outputs[outputs.length - 1].completedAt as Date),
    });
    Generation.Image.Create.Latest.set(undefined);
  };

  const generatedImageRows = useMemo(
    () =>
      outputs.map((output) => {
        const outputID = output.id;
        if (!outputID) return null;
        return (
          <div key={outputID}>
            <Generation.Image.Output
              placeholder={false}
              divider={true}
              outputID={outputID}
            />
          </div>
        );
      }),
    [outputs],
  );

  const existingImagesDiv = (
    <>
      <Generation.Images.Modal />
      <div
        ref={parentRef}
        className={classes(
          "relative flex shrink grow justify-center gap-4 overflow-y-auto p-6",
          className,
        )}
      >
        <div className="relative flex w-full flex-col gap-y-6">
          <div className="flex w-full flex-row justify-between gap-4">
            <div className="flex w-full flex-row items-center gap-1">
              <img
                className="h-7 w-7 shrink-0 overflow-hidden"
                alt=""
                src="gallery.svg"
              />
              <div className="font-inter text-left font-light text-white sm:text-base md:text-xl">
                Art gallery
              </div>
            </div>
            {(isFetching || isLoading) && (
              <Theme.Loading.Custom
                className="self-center pl-1 opacity-50"
                type="spinningBubbles"
                height={isMobileDevice ? 30 : 50}
                width={isMobileDevice ? 30 : 50}
              />
            )}
          </div>
          <div className="h-full w-full shrink grow">
            {outputs.length > 0 ? (
              <div className="flex flex-col">{generatedImageRows}</div>
            ) : (
              <div className={classes("flex flex-col gap-20")}>
                <div className="xs:grid-cols-1 grid w-full shrink gap-4 overflow-hidden rounded-b-none rounded-tr-none sm:grid-cols-2 lg:grid-cols-4">
                  {noImagesDiv}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const paginationControl = (
    <div className="text-whitesmoke font-inter flex flex-row justify-between px-4 text-base">
      <Theme.Button
        label="Previous"
        className={classes(page > 1 && "hover:font-semibold")}
        iconLeft={<Theme.Icon.ArrowLeft className="h-5" />}
        color="secondary"
        onClick={() => previousClick()}
        disabled={page == 1}
      >
        Previous
      </Theme.Button>
      <div className="flex flex-row items-center gap-3 lg:gap-8">
        {isLoading && <Theme.Loading.Spinner className="h-5 w-5" />}
        {totalPages}
      </div>
      <Theme.Button
        label="Next"
        iconRight={<Theme.Icon.ArrowRight className="h-5" />}
        color="secondary"
        className={classes(page < totalPages.length && "hover:font-semibold")}
        disabled={page == totalPages.length || pageList.length == 0}
        onClick={() => nextClick()}
      >
        Next
      </Theme.Button>
    </div>
  );

  return (
    <>
      {existingImagesDiv}
      {outputs.length > 0 && paginationControl}
    </>
  );
}

export declare namespace Images {
  export { Delete, Download, Modal, State, Scroll, Feedback };
}

export namespace Images {
  Images.Delete = Delete;
  Images.Download = Download;
  Images.Modal = Modal;
  Images.State = State;
  Images.Scroll = Scroll;
  Images.Feedback = Feedback;

  export type Props = Styleable & { minimum?: number };
  export type Options = {
    input?: ID;
    project?: ID;
    sortOrder?: "descending" | "ascending";
    page: number;
    forward: boolean;
    limit: number;
    //  stopID?: ID;
    startDate?: Date;
    stopDate?: Date;
  };

  export const add = (images: Images) => State.get().addImages(images);

  export const use = (): Images =>
    State.use(({ images }) => Object.values(images), GlobalState.shallow);

  export const useFromIDs = (...ids: IDs): Images =>
    State.use(
      ({ images }) =>
        ids
          .filter((id) => id !== undefined)
          .map((id) => images[id as ID])
          .filter((image): image is Generation.Image => image !== undefined),
      GlobalState.shallow,
    );
}
