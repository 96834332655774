import { useEffect, useState } from "react";
import { Theme } from "~/Theme";

// Image component
interface ImageProps {
  computeId?: string;
  imageUrl?: string;
  disabled: boolean;
  selected: boolean;
  selectedCount: number;
  select: () => void;
  maximize: () => void;
}

function getOpacity({
  selectedCount,
  selected,
  disabled,
}: {
  selectedCount: number;
  selected: boolean;
  disabled: boolean;
}) {
  if (disabled) return "opacity-30";

  if (!selectedCount) return "opacity-100";

  if (!selected) return "opacity-100";

  return "opacity-50";
}

export function Images({
  computeId,
  imageUrl,
  selected,
  select,
  disabled,
  maximize,
  selectedCount,
}: ImageProps) {
  const opacity = getOpacity({ disabled, selected, selectedCount });
  const isMobileDevice = Theme.useIsMobileDevice();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [imageUrl]);

  return (
    <div
      className={classes(
        "group relative aspect-square w-full",
        selected && "group-[.submit]-hover:bg-green-500 rounded-md bg-opacity-50",
      )}
    >
      {selected && (
        <Theme.Icon.CheckCircle
          className={classes(
            "text-brand-1000 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform",
            opacity,
          )}
          size={isMobileDevice ? 32 : 64}
        />
      )}
      <Theme.Icon.Maximize2
        className={classes(
          "absolute right-3 top-3 z-10 opacity-80",
          "hidden group-hover:block",
        )}
        onClick={maximize}
        size={20}
      />
      {imageUrl && (
        <>
          {!imageLoaded && (
            <div className="absolute inset-0 animate-pulse rounded bg-gray-200"></div>
          )}
          <img
            src={imageUrl}
            onClick={select}
            alt={`Image for ${computeId}`}
            className={classes(
              "z-0 h-full w-full cursor-pointer rounded object-cover",
              opacity,
              imageLoaded ? "opacity-100" : "opacity-0",
              "transition-opacity duration-300",
            )}
            onLoad={() => setImageLoaded(true)}
          />
        </>
      )}
    </div>
  );
}
