import { Round } from "@stability/alchemy-studio-plugin";
import { AxiosError } from "axios";

import { GlobalAPI } from "../GlobalAPI";

export namespace HvpAPI {
  const ERR_OVER_VOTE = "OVER_VOTE";
  const ERR_DOUBLE_VOTE = "DOUBLE_VOTE";
  const ERR_NOT_FOUND = "NOT_FOUND";
  const CODE_RATE_LIMIT = 429;
  const MSG_NO_ROUNDS_FOUND =
    "Looks like you've voted on everything for now, great job";
  const MSG_NO_RATE_LIMIT = "You've voted on everything for today";

  /**
   * Function to HVP backend call to get /rounds
   * @param options
   * @returns
   */
  export const getRounds = async (options: {
    accessToken: string;
  }): Promise<Round[] | undefined> => {
    const rounds: Round[] = [];
    try {
      //TODO: to be removed later
      const access_token = options?.accessToken; //await GlobalAPI.getAuthToken();
      const response = await GlobalAPI.fetchData("/rounds", {
        method: "POST",
        headers: { Authorization: `Bearer ${access_token}` },
      });
      const expiry = new Date(response?.data?.expiry * 1000);
      rounds.push({ ...response.data, expiry });
      return rounds;
    } catch (error) {
      if (error instanceof AxiosError && error?.response) {
        const message =
          error.response.status == CODE_RATE_LIMIT
            ? MSG_NO_RATE_LIMIT
            : error.response?.data?.cause == ERR_NOT_FOUND
            ? MSG_NO_ROUNDS_FOUND
            : undefined;
        //Propagate message to display on UI
        if (message) {
          rounds.push({ message });
          return rounds;
        }
      }
      //Propagate to sentry
      throw error;
    }
  };

  /**
   * Function to HVP backend call to get /submitVotes
   * @param options
   */
  export const submitVotes = async (
    options: { accessToken: string } & Round,
  ): Promise<void> => {
    const { computes, id } = { ...options };
    const selected = computes?.map((item) => item.id);
    try {
      //TODO: to be removed later
      const access_token = options?.accessToken; //await GlobalAPI.getAuthToken();
      if (!access_token) {
        throw new Error("Failed to get access token");
      }

      await GlobalAPI.fetchData("/votes", {
        method: "POST",
        headers: { Authorization: `Bearer ${access_token}` },
        body: { compute_ids: Object.values(selected as []), round_id: id },
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.data?.cause == ERR_OVER_VOTE ||
          error.response?.data?.cause == ERR_DOUBLE_VOTE
        ) {
          //Ignore double vote
          return;
        }
      }
      throw error;
    }
  };
}
