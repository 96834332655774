import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export function UploadImage({ id }: { id: ID }) {
  const { setInput, importInit, input } = Generation.Image.Input.use(id);
  const setInitialImage = Generation.Image.Session.useSetInitialImage();
  const setLastStrength = Generation.Image.Session.useSetLastStrength();
  const aspectRatio = useMemo(
    () => (input?.width && input?.height ? input.width / input.height : 1),
    [input],
  );

  return (
    <div>
      <Theme.Button
        label="Add Image"
        onClick={importInit}
        size="lg"
        color="secondary"
        icon={
          <img
            className=" flex h-[20px] w-[20px]"
            alt="AlchemyStudio"
            src="UploadImg.svg"
          />
        }
        className="h-[44px] w-full"
      >
        Add Image
      </Theme.Button>
    </div>
  );
}

export namespace UploadImage {}
