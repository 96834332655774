import React, { useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { Router } from "~/App/Router";
import { TopBar } from "~/App/TopBar";
import { GlobalConstants } from "~/constants/GlobalConstants";
import { Routes } from "~/constants/Routes";
import { Generation } from "~/Generation";

import { Theme } from "~/Theme";

import { UserInfo } from "~/types";

import { useAuth } from "../hooks/useAuth";

import { LeftBanner } from "./LeftBanner";

import { State } from "./State";

export function Login() {
  const navigate = Router.useNavigate();
  const showErrorSnackbar = Generation.Image.Exception.Snackbar.showMessage();
  const { login, loading } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const deviceType = Theme.useDevice();
  const isMobileDevice = Theme.useIsMobileDevice();

  const submit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const result = await login({ email, password, remember });

    if (result.success && result.data?.emailVerified) {
      navigate(Routes.GENERATE);
    } else {
      const exception = Generation.Image.Exception.create(
        result.error ?? "Invalid Credentials",
      );
      showErrorSnackbar(exception, "center", "top", "error");
    }
  };

  const leftBanner = useMemo(() => {
    return deviceType === "large" && <LeftBanner />;
  }, [deviceType]);

  const onSet = (value: any) => setRemember(value);

  return (
    <>
      <div className="bg-brand-50 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden text-white">
        <TopBar />
        <div
          className={classes(
            "bg-brand-50 z-0 flex flex-row justify-between overflow-hidden md:max-w-[1348px]",
            isMobileDevice && "overflow-auto",
          )}
        >
          {leftBanner}
          <div className="flex flex-col gap-2 p-8">
            <div className="flex flex-row justify-center gap-4">
              {!isMobileDevice && <Theme.Logo className="h-10 w-10 rounded" />}
              <div className="font-inter text-whitesmoke xxsm:text-2xl self-center text-left">
                Welcome to Alchemy Studio
              </div>
            </div>
            <div className="bg-brand-100 relative flex w-full flex-col gap-6  rounded-lg p-4">
              <div className="flex flex-col gap-5">
                <form className="flex flex-col gap-4" onSubmit={submit}>
                  <Theme.Label>Email</Theme.Label>
                  <Theme.Input
                    value={email}
                    type="email"
                    onChange={setEmail}
                    onKeyDown={(event: any) => event.key === "Enter" && submit}
                    placeholder="Email"
                    className="h-[44px]"
                  />
                  <Theme.Label>Password</Theme.Label>
                  <div className="flex flex-row">
                    <Theme.Input
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={setPassword}
                      onKeyDown={(event: any) => event.key === "Enter" && submit}
                      className="h-[44px]"
                      placeholder="••••••••"
                    />
                    <div
                      className="dark:placeholder:text-muted-white bg-brand-50 flex h-[44px] text-black shadow-sm focus:border-transparent focus:outline-none dark:border-none dark:text-white"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <Theme.Icon.Eye className="self-center opacity-50" />
                      ) : (
                        <Theme.Icon.EyeOff className="self-center opacity-50" />
                      )}
                    </div>
                  </div>
                  <div className="flex grow basis-0 items-center justify-between gap-4">
                    <Theme.Checkbox
                      size="md"
                      bold
                      value={remember}
                      onChange={onSet}
                      labelClassName="text-sm"
                      label="Remember for next time"
                    />
                    <Link
                      className="cusrsor-pointer text-brand-1000 flex flex-row items-center gap-3 text-sm font-semibold hover:underline hover:underline-offset-4"
                      to={Routes.FORGOT_PASSWORD}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <Theme.Button
                    color="brand"
                    fullWidth
                    loading={loading}
                    label="Sign In"
                    className="font-inter text-whitesmoke h-[44px] w-full text-base font-medium"
                  >
                    Log In
                  </Theme.Button>
                </form>
              </div>
            </div>
            <div className="bg-brand-100 relative flex w-full flex-col gap-[24px] rounded-lg p-4">
              <div className="flex flex-col gap-4 text-center text-sm text-slate-500">
                Don&apos;t have an account?{" "}
                <Theme.Button
                  label="Sign Up"
                  size="lg"
                  color="secondary"
                  onClick={() => navigate(Routes.SIGN_UP)}
                  className="font-inter text-whitesmoke h-[44px] w-full text-base font-medium"
                >
                  Sign Up
                </Theme.Button>
              </div>
            </div>
            <div className="relative w-[374px] text-center text-sm text-slate-500">
              By signing up, you agree to our Terms of Service and Privacy Policy
              Agreement.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export declare namespace User {
  export { State };
}

export namespace User {
  User.State = State;
  export const add = (userInfo: UserInfo) => {
    //State.get().addUser(userInfo);
    localStorage.setItem(GlobalConstants.USER_INFO, JSON.stringify(userInfo));
  };

  export const use = (): UserInfo =>
    State.use(({ userInfo }) => {
      //  console.log(userInfo);
      if (!userInfo?.email) {
        const user = localStorage.getItem(GlobalConstants.USER_INFO);
        if (user) {
          return JSON.parse(user);
        }
      }
      return userInfo;
    });

  export const remove = () => {
    localStorage.removeItem(GlobalConstants.USER_INFO);
  };

  export const update = (userInfo: Partial<UserInfo>) => {
    const currentUser = JSON.parse(
      localStorage.getItem(GlobalConstants.USER_INFO) || "{}",
    );
    const updatedUser = { ...currentUser, ...userInfo };
    localStorage.setItem(GlobalConstants.USER_INFO, JSON.stringify(updatedUser));
    State.get().addUser(updatedUser);
  };
}
