import { GlobalConstants, UserInfo } from "@stability/alchemy-studio-plugin";
import axios, { AxiosRequestConfig } from "axios";
import { generateBackendToken } from "../Firebase/Firebase";
declare global {
  interface ImportMeta {
    readonly env: Environment;
  }

  interface ImportMetaEnv {
    readonly VITE_FIREBASE_PROJECT_API_KEY: string;
    readonly VITE_FIREBASE_AUTH_DOMAIN: string;
    readonly VITE_FIREBASE_PROJECT_ID: string;
    readonly VITE_FIREBASE_STORAGEBUCKET: string;
    readonly VITE_FIREBASE_MESSAGINGID: string;
    readonly VITE_FIREBASE_APPID: string;
    readonly VITE_FIREBASE_MEASUREMENTID: string;
    readonly VITE_FIREBASE_DEFAULT_REGION: string;
    readonly VITE_NODE_ENV: string;
    readonly VITE_HOST_API: string;
    readonly VITE_CLIENT_ID: string;
  }
}

export namespace GlobalAPI {
  const HOST_API = import.meta.env.VITE_HOST_API;
  const ACCESS_TOKEN_EXPIRY = "access_token_expiry";
  axios.defaults.withCredentials = true;
  export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST";
  export const ERR_NETWORK = "ERR_NETWORK";

  // Helper function to call GET and POST using axios
  export const fetchData = async (url: string, options: any = {}): Promise<any> => {
    //Default axios config
    const defaultOptions: AxiosRequestConfig = {
      method: "GET",
      baseURL: HOST_API,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      url: url,
      data: JSON.stringify(options?.body),
    };

    //Merging axios config with parameters
    const mergedOptions: AxiosRequestConfig = {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...options.headers,
      },
    };
    const response = await axios(mergedOptions);
    return response;
  };

  /**
   * Function to refresh token with existing httpOnly cookie access_token
   */
  export const refreshToken = async (): Promise<void> => {
    const { data } = await fetchData("/auth/refresh", { method: "POST" });
    if (data) {
      localStorage.setItem(ACCESS_TOKEN_EXPIRY, new Date().toString());
    }
  };

  /**
   * Function to get httponly auth token for backend verification
   * @returns
   */

  export const getAuthToken = async (): Promise<string | undefined> => {
    //Check if token is already present in the state
    // const { accessToken } = getToken();
    // if (accessToken) return accessToken;
    //Issue new token
    return await generateBackendToken();
  };

  export const getUserId = async (): Promise<string> => {
    const userInfoJson = localStorage.getItem(GlobalConstants.USER_INFO);
    if (userInfoJson != null) {
      const userId = JSON.parse(userInfoJson)?.userId;
      if (userId) {
        return userId;
      }
    }

    const accessToken = await generateBackendToken();
    const { data } = await fetchData("/auth", {
      method: "get",
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    //Add user_id in storage
    if (data.user_id) {
      const userData = localStorage.getItem(GlobalConstants.USER_INFO);
      const userId = data.user_id;
      if (userData) {
        const userInfo: UserInfo = {
          ...JSON.parse(userData),
          userId,
        };
        localStorage.setItem(GlobalConstants.USER_INFO, JSON.stringify(userInfo));
      }
    }
    return data.user_id;
  };
}
export type Environment = ImportMetaEnv;
