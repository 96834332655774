import * as ReactQuery from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { IdpAPI } from "~/api/IdpAPI";
import { App } from "~/App";
import { User } from "~/components/auth/Login";
import { Theme } from "~/Theme";
import { UIMessage } from "~/UIMessage";

const UserInfo = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const user = User.use();

  const token = App.getToken();
  const fetchTrustLevel = async () => {
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    return await IdpAPI.getTrustLevel({ accessToken: token });
  };

  const { data: trustLevel } = ReactQuery.useQuery({
    queryKey: ["Validation.TrustLevel"],
    queryFn: fetchTrustLevel,
    enabled: !!token,
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user?.uid ?? "");
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  if (!user || !trustLevel) return null;

  return (
    <div className="bg-brand-200 mb-4 rounded-lg p-4 shadow-md">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-200">User ID:</span>
          <div className="bg-brand-50 flex items-center rounded-md p-1 px-2">
            <span className="text-sm font-semibold">{user.uid}</span>
          </div>
          {copySuccess ? (
            <Theme.Icon.Check size={16} className=" text-green-500" />
          ) : (
            <Theme.Icon.Copy
              size={16}
              className="cursor-pointer text-gray-400 hover:text-gray-200"
              onClick={copyToClipboard}
            />
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-200">Trust Level:</span>
          <span
            className={`rounded px-2 py-1 text-sm font-semibold ${
              trustLevelColors[trustLevel.code]
            }`}
          >
            {trustLevel.level}
          </span>
          <Theme.Tooltip content={trustLevel.description}>
            <Theme.Icon.Info size={16} className="cursor-help text-gray-400" />
          </Theme.Tooltip>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

const trustLevelColors: Record<string, string> = {
  EX: "bg-purple-500 text-white",
  VH: "bg-green-500 text-white",
  GD: "bg-blue-500 text-white",
  LW: "bg-yellow-500 text-black",
  VL: "bg-orange-500 text-white",
  RB: "bg-red-500 text-white",
};
