import { Wallet as WalletData } from "@stability/alchemy-studio-plugin";
import { Link } from "react-router-dom";
import { WalletAPI } from "~/api/WalletAPI";
import { GlobalState } from "~/GlobalState";
import { Theme } from "~/Theme";

import { CreateWallet } from "./CreateWallet";
import { SetupWallet } from "./SetupWallet";
import { State } from "./State";
import { WalletInfo } from "./WalletInfo";

export function Wallet() {
  const [openCreateWallet, setOpenCreateWallet] = useState(false);
  const [openSetupWallet, setOpenSetupWallet] = useState(false);
  const [walletUpdated, setWalletUpdated] = useState(false);
  const { address } = Wallet.use();

  const createWalletModal = useMemo(() => {
    return (
      <Theme.Modal
        modalName="CreateWallet"
        open={openCreateWallet}
        onClose={() => {
          setOpenCreateWallet(false);
        }}
        className="overflow-y-auto"
      >
        <Theme.Modal.Panel className="relative justify-center gap-5 overflow-y-auto p-4">
          <Wallet.CreateWallet onClose={() => setOpenCreateWallet(false)} />
        </Theme.Modal.Panel>
      </Theme.Modal>
    );
  }, [openCreateWallet]);

  const setUpWalletModal = useMemo(() => {
    return (
      <Theme.Modal
        modalName="SetupWallet"
        open={openSetupWallet}
        onClose={() => setOpenSetupWallet(false)}
        className="max-h-[90%] overflow-y-auto"
      >
        <Theme.Modal.Panel className="relative justify-center gap-5 overflow-y-auto p-4">
          <Wallet.SetupWallet
            onClose={() => setOpenSetupWallet(false)}
            onConfirm={() => setWalletUpdated(true)}
          />
        </Theme.Modal.Panel>
      </Theme.Modal>
    );
  }, [openSetupWallet]);

  return (
    <div id="wallet">
      {createWalletModal}
      {setUpWalletModal}
      {address ? (
        <>
          <Wallet.WalletInfo
            address={address}
            setUpWallet={() => setOpenSetupWallet(true)}
            walletUpdated={walletUpdated}
          />
        </>
      ) : (
        <div
          className={classes(
            "bg-brand-100 relative z-[1] flex grow select-text flex-col justify-center gap-4 overflow-hidden rounded-lg p-4 sm:max-w-[600px] lg:max-w-full",
          )}
        >
          <div className="flex flex-row items-center justify-between">
            <span className="text-whitesmoke p-2 text-2xl">Setup your wallet</span>
            <Link
              className="cusrsor-pointer text-brand-1000 text-base font-semibold hover:underline hover:underline-offset-4"
              to="/validate"
            >
              {"<< Back"}
            </Link>
          </div>
          <div className="items-ceneter flex flex-row gap-1">
            <Theme.Icon.Info size={16} className="opacity-50" />
            <span className="text-whitesmoke text-sm opacity-50">
              Create or set a custom wallet below to get paid for your votes!
            </span>
          </div>
          <div className="bg-brand-50 flex min-h-[200px] flex-row flex-row items-center justify-center gap-3 rounded-lg">
            {!address && (
              <Theme.Button
                color="ternary"
                className="h-11"
                iconLeft={<Theme.Icon.CreditCard size={20} />}
                onClick={() => setOpenCreateWallet(true)}
              >
                Create Wallet
              </Theme.Button>
            )}
            <Theme.Button
              color="ternary"
              className="h-11"
              iconLeft={<Theme.Icon.Wallet size={20} />}
              onClick={() => setOpenSetupWallet(true)}
            >
              Setup Wallet
            </Theme.Button>
          </div>
        </div>
      )}
    </div>
  );
}

export declare namespace Wallet {
  export { CreateWallet, SetupWallet, WalletInfo, State };
  // Round component
  export interface WalletProps {
    mnemonic?: string;
    address?: string;
    walletUpdated?: boolean;
    onClose?: () => void;
    setUpWallet?: () => void;
  }
}
export namespace Wallet {
  Wallet.CreateWallet = CreateWallet;
  Wallet.SetupWallet = SetupWallet;
  Wallet.WalletInfo = WalletInfo;
  Wallet.State = State;
  const SS58_CHARSET = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  export const add = (value: WalletData) => {
    State.get().addWallet(value);
  };

  export const use = (): WalletData =>
    State.use(({ walletInfo }) => walletInfo, GlobalState.shallow);

  export const validateSS58Address = (address: string): boolean => {
    if (typeof address !== "string") {
      return false;
    }
    if (address.length < 47 || address.length > 48) {
      return false;
    }
    for (let i = 0; i < address.length; i++) {
      if (SS58_CHARSET.indexOf(address[i]) === -1) {
        return false;
      }
    }
    return true;
  };

  export const updateWalletState = async (token: string) => {
    const { address } = await WalletAPI.getWalletInfo({ accessToken: token });
    //Save wallet state
    Wallet.add({ address });
  };
}
