export namespace RandomImage {
  export const randomIntFromInterval = (): number[] => {
    const arr: number[] = [];
    while (arr.length < 24) {
      const r = Math.floor(Math.random() * 25) + 1;
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  };

  export const firstRow = [
    "/media/landingpage/secondrow/image7.png",
    "/media/landingpage/secondrow/image1.png",
    "/media/landingpage/secondrow/image2.png",
    "/media/landingpage/secondrow/image3.png",
    "/media/landingpage/firstrow/image1.png",
    "/media/landingpage/firstrow/image2.png",
    "/media/landingpage/firstrow/image3.png",
    "/media/landingpage/firstrow/image4.png",
    "/media/landingpage/firstrow/image5.png",
    "/media/landingpage/firstrow/image6.png",
    "/media/landingpage/secondrow/image4.png",
    "/media/landingpage/secondrow/image5.png",
    "/media/landingpage/secondrow/image6.png",
    "/media/landingpage/secondrow/image7.png",
  ];

  export const secondRow = [
    "/media/landingpage/firstrow/image1.png",
    "/media/landingpage/firstrow/image2.png",
    "/media/landingpage/firstrow/image3.png",
    "/media/landingpage/secondrow/image1.png",
    "/media/landingpage/secondrow/image2.png",
    "/media/landingpage/secondrow/image3.png",
    "/media/landingpage/secondrow/image4.png",
    "/media/landingpage/secondrow/image5.png",
    "/media/landingpage/secondrow/image6.png",
    "/media/landingpage/secondrow/image7.png",
    "/media/landingpage/firstrow/image4.png",
    "/media/landingpage/firstrow/image5.png",
    "/media/landingpage/firstrow/image6.png",
  ];

  export const loginImages = [
    "/media/landingpage/secondrow/image7.png",
    "/media/landingpage/secondrow/image1.png",
    "/media/landingpage/secondrow/image2.png",
    "/media/landingpage/secondrow/image3.png",
    "/media/landingpage/firstrow/image1.png",
    "/media/landingpage/firstrow/image2.png",
    "/media/landingpage/firstrow/image3.png",
    "/media/landingpage/firstrow/image4.png",
    "/media/landingpage/firstrow/image5.png",
    "/media/landingpage/firstrow/image6.png",
    "/media/landingpage/secondrow/image4.png",
    "/media/landingpage/secondrow/image5.png",
    "/media/landingpage/secondrow/image6.png",
    "/media/landingpage/secondrow/image7.png",
    "/media/landingpage/firstrow/image1.png",
    "/media/landingpage/firstrow/image2.png",
    "/media/landingpage/firstrow/image3.png",
    "/media/landingpage/secondrow/image1.png",
    "/media/landingpage/secondrow/image2.png",
    "/media/landingpage/secondrow/image3.png",
    "/media/landingpage/secondrow/image4.png",
    "/media/landingpage/secondrow/image5.png",
    "/media/landingpage/secondrow/image6.png",
    "/media/landingpage/secondrow/image7.png",
    "/media/landingpage/firstrow/image4.png",
    "/media/landingpage/firstrow/image5.png",
    "/media/landingpage/firstrow/image6.png",
  ];
}
