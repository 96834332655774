import { Navigate, Route, Routes } from "react-router-dom";
import { App } from "~/App";
import { ForgotPassword } from "~/components/auth/ForgotPassword";
import { Login, User } from "~/components/auth/Login";
import { DiscordAuth } from "~/components/auth/Login/DiscordAuth";
import { Signup } from "~/components/auth/Signup";
import { Routes as PageRoutes } from "~/constants/Routes";

import PrivateRoutes from "./PrivateRoutes";

export function AuthRouter() {
  const user = User.use();

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<App />} path="*" />
      </Route>
      <Route
        element={user.emailVerified ? <Navigate to="/" /> : <Login />}
        path={PageRoutes.LOGIN}
      />
      <Route
        element={user.emailVerified ? <Navigate to="/" /> : <Signup />}
        path={PageRoutes.SIGN_UP}
      />
      <Route element={<ForgotPassword />} path={PageRoutes.FORGOT_PASSWORD} />
      <Route element={<DiscordAuth />} path={PageRoutes.DISCORD_AUTH} />
    </Routes>
  );
}
