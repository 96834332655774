import { Feedback as UserFeedback } from "@stability/alchemy-studio-plugin";
import { GenerationAPI } from "~/api/GenerationAPI";
import { App } from "~/App";
import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export function Button({
  variant,
  feedbackToolTip,
  className,
  image,
  outline,
}: Styleable & {
  // TODO: This sucks
  variant?: "group";
  feedbackToolTip?: string;
  image: Partial<Generation.Image>;
  outline?: boolean;
}) {
  const [like, setLike] = useState(image?.feedback?.like ?? false);
  const [dislike, setDisLike] = useState(image?.feedback?.dislike ?? false);
  const isMobileDevice = Theme.useIsMobileDevice();
  const accessToken = App.getToken();

  //Submit feedback to backend
  const onClickFeedback = async (feedback: UserFeedback) => {
    image.feedback = { ...feedback };
    setLike(feedback.like);
    setDisLike(feedback.dislike);
    if (accessToken && image.id) {
      await GenerationAPI.submitfeedback({
        accessToken,
        task: {
          id: image.inputID,
          computes: [{ id: image.id, feedback }],
        },
      });
    }
  };

  return useMemo(
    () => (
      <div
        className={classes(
          isMobileDevice
            ? "bg-brand-100 flex flex-row"
            : "-py-1 bg-brand-100 rounded px-1",
          className,
        )}
      >
        <Generation.Image.Controls.Button
          alwaysShow={variant === "group"}
          name={"Like"}
          icon={(props: any) => (
            <Theme.Icon.ThumbsUp
              {...props}
              className={classes(
                props.className,
                "text-white-500/75 origin-center scale-[0.9]",
                isMobileDevice ? "h-4 w-4" : "hover:text-brand-1100 h-6 w-6 ",
                like && "text-brand-1100 fill-white opacity-40",
              )}
            />
          )}
          onClick={() => {
            onClickFeedback({ like: !like, dislike: false });
          }}
          transparent
          noBg
        />
        <Generation.Image.Controls.Button
          name="Dislike"
          alwaysShow={variant === "group"}
          onClick={() => onClickFeedback({ like: false, dislike: !dislike })}
          icon={(props: any) => (
            <Theme.Icon.ThumbsDown
              {...props}
              className={classes(
                props.className,
                "text-white-500/75 origin-center scale-[0.9]",
                isMobileDevice ? "h-4 w-4" : "hover:text-brand-1100 h-6 w-6 ",
                dislike && "text-brand-1100 fill-white opacity-40",
              )}
            />
          )}
          transparent
          noBg
        />
      </div>
    ),
    [like, dislike, className, variant, feedbackToolTip, outline, image],
  );
}
