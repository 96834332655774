import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export function AddImage({ id }: { id: ID }) {
  const { setInput, importInit, input } = Generation.Image.Input.use(id);
  const deviceType = Theme.useDevice();

  return (
    <>
      <Theme.Button
        label="Add Image"
        onClick={importInit}
        size="lg"
        color="secondary"
        icon={
          <img
            className=" flex h-7 w-7 text-white"
            alt="AlchemyStudio"
            src="UploadImg.svg"
          />
        }
        className="xxsm:w-32 h-[44px] sm:w-full"
      >
        {!(deviceType == "small") && <>Add Image</>}
      </Theme.Button>
    </>
  );
}

export function EditImage({ id }: { id: ID }) {
  const { setInput, importInit, input } = Generation.Image.Input.use(id);
  return (
    <div>
      <div className="flex flex-row gap-2">
        <Theme.Button
          onClick={importInit}
          size="lg"
          color="secondary"
          icon={
            <img
              className=" flex h-[20px] w-[20px]"
              alt="AlchemyStudio"
              src="UploadImg.svg"
            />
          }
          className="xxsm:w-32 h-[44px] py-0 sm:w-full"
        >
          Edit Image
        </Theme.Button>
        {(input?.init as any)?.src && (
          <Theme.Button
            size="lg"
            color="secondary"
            className="xxsm:w-32 h-[44px] py-0 sm:w-full"
            onClick={() => {
              setInput((input) => {
                input.init = null;
              });
            }}
          >
            Cancel
          </Theme.Button>
        )}
      </div>
    </div>
  );
}

export declare namespace Buttons {
  export { AddImage, EditImage };
}

export namespace Buttons {
  Buttons.AddImage = AddImage;
  Buttons.EditImage = EditImage;
}
