import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export namespace Shuffle {
  export function Button({
    inputID,
    index,
    ...props
  }: Theme.Button.Props & { inputID: ID }) {
    const { input, shuffle } = Generation.Image.Input.use(inputID);

    const label = useMemo(
      () => `Random prompt${input?.prompts.length ?? 0 > 1 ? "s" : ""}`,
      [input?.prompts.length],
    );
    const deviceType = Theme.useDevice();

    return (
      <Theme.Button
        size="lg"
        label={label}
        color="secondary"
        icon={<Theme.Icon.Dices className="flex h-7 w-7" />}
        onClick={() => shuffle(index)}
        {...props}
      >
        {!(deviceType == "small") && <>Random prompt</>}
      </Theme.Button>
    );
  }
}
