import * as AlchemyStudio from "@stability/alchemy-studio-plugin";
import {
  GenerateImageResponse,
  TaskState,
  UserTaskHistoryResponse,
} from "@stability/alchemy-studio-plugin";
import { Timestamp } from "firebase/firestore";

import {
  getImageUrl,
  getThumbNailUrl,
  getTotalNumPages,
  logStartTime,
  logStopTime,
  queryExistingImages,
} from "../Firebase/Firebase";
import { GlobalAPI } from "../GlobalAPI";

/**
 * API calls for generate image task
 */
export namespace GenerationAPI {
  export interface Compute {
    id: string;
    created: Date;
    compute_url: string;
    thumbnail_url: string;
    prompt?: string;
  }

  /**
   * API call to complete task TEXT_TO_IMAGE
   * @param input
   * @returns
   */
  export const generateImage = async (
    input: AlchemyStudio.StableDiffusionInput & { imgCount?: number },
    accessToken: string,
  ): Promise<GenerateImageResponse | undefined> => {
    const prompt = input?.prompts?.filter(
      (prompt) => prompt.weight && prompt.weight > 0,
    )[0]?.text;
    const negative_prompt = input?.prompts?.filter(
      (prompt) => prompt.weight && prompt.weight < 0,
    )[0]?.text;
    const seed = input?.seed == 0 ? -1 : input?.seed;
    const { steps, height, width } = { ...input };
    const guidance_scale = input?.cfgScale;
    const num_images_per_prompt = input.imgCount ?? 1;

    //Calling generate image task API
    try {
      const response = await GlobalAPI.fetchData("/tasks/text_to_image", {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          prompt,
          negative_prompt,
          seed,
          steps,
          guidance_scale,
          width,
          height,
          image_count: num_images_per_prompt,
        },
      });

      //Return task details
      return { ...response.data };
    } catch (error) {
      throw error;
    }
  };

  /**
   * Function to get TASK details
   * @param options.taskid taskid
   * @returns
   */
  export const getGenerateImageTaskResult = async (options: {
    accessToken: string;
    taskId: string;
  }): Promise<GenerateImageResponse> => {
    const { accessToken, taskId } = { ...options };
    try {
      //Call API to get task details
      const response = await GlobalAPI.fetchData(`/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  /**
   * Function to query MongoDb + FirebaseDB and merge response
   * @param options.paginationdata pagination meta data coming from user input
   * @param options.accessToken /auth token
   * @returns
   */
  export const getUserTasksFromHistory = async (options: {
    paginationData: AlchemyStudio.PaginationData;
    accessToken: string;
  }): Promise<UserTaskHistoryResponse> => {
    const { accessToken, paginationData } = { ...options };
    const user_id = await GlobalAPI.getUserId();
    const pagenum = `page=${paginationData.page}`;
    const limit = `size=${paginationData.limit}`;
    const task_state = `task_state=${TaskState.COMPLETED}`;
    let userTaskResponse: UserTaskHistoryResponse = { items: [] };
    try {
      const response = await GlobalAPI.fetchData(
        `/users/${user_id}/tasks?${pagenum}&${limit}&${task_state}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      const data = { ...response.data };
      const firebasePages = await getTotalNumPages(paginationData.limit);
      //Check if Alchemy backend has no records
      const page = data.page;
      const pages = data.pages + firebasePages;
      const size = data.size;
      const totalRecords = data.total;

      userTaskResponse = {
        ...userTaskResponse,
        paginationData: {
          page,
          pages,
          totalRecords,
          limit: size,
        },
      };

      if (data?.items.length > 0) {
        const taskList: GenerateImageResponse[] = [];
        for (const item of data.items) {
          const created = new Date(item.created * 1000);
          const prompts: AlchemyStudio.StableDiffusionPrompt[] = [];

          prompts.push({
            text: item.prompt,
            weight: 1,
          });

          prompts.push({
            text: item.negative_prompt,
            weight: -1,
          });

          const input: AlchemyStudio.StableDiffusionInput = {
            cfgScale: item.guidance_scale,
            width: item.width,
            height: item.height,
            steps: item.steps,
            seed: item.seed,
            prompts,
          };

          taskList.push({ ...item, created, input });
        }
        userTaskResponse = { ...userTaskResponse, items: taskList };
      }

      //Query firebase DB and merge the history
      if (data?.items.length < size || data?.items.length == 0) {
        const firebaseLimit = paginationData.limit - data?.items.length;
        const firebaseData = await getUserTasksFromFirebaseDB({
          paginationData: { ...paginationData, limit: firebaseLimit },
        });

        if (data?.items.length < size) {
          const totalPages = pages - 1;
          userTaskResponse = {
            ...userTaskResponse,
            paginationData: { ...paginationData, pages: totalPages },
          };
        }

        if (firebaseData) {
          const mergedItems = [...userTaskResponse.items, ...firebaseData];
          userTaskResponse = { ...userTaskResponse, items: mergedItems };
        }
      }

      return userTaskResponse;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  /**
   * Get the firebase DB history if any exists
   * @param options
   * @returns
   */
  export const getUserTasksFromFirebaseDB = async (options: {
    paginationData: AlchemyStudio.PaginationData;
  }): Promise<GenerateImageResponse[] | undefined> => {
    const imageRecords: GenerateImageResponse[] = [];
    const { paginationData } = { ...options };

    logStartTime("Query Firebase DB");
    const dbImageList = await queryExistingImages({
      forward: paginationData.forward,
      limit: paginationData.limit,
      paginationKey: paginationData.paginationKey,
    });
    logStopTime("Query Firebase DB");

    if (dbImageList) {
      for (const imageData of dbImageList) {
        const id = imageData.id;
        if (!id) return undefined;
        const imageID = imageData.id;
        if (!imageID) return undefined;
        const createdAt = (imageData?.generated_on as Timestamp)?.toDate();
        const model = imageData.id;
        const seed = Number(imageData.seed);
        const prompts: AlchemyStudio.StableDiffusionPrompt[] = [];
        prompts.push({
          text: imageData.prompt,
          weight: 1,
        });

        prompts.push({
          text: imageData.negative_prompt,
          weight: -1,
        });

        const input: AlchemyStudio.StableDiffusionInput = {
          prompts,
          model,
          style: "enhance",
          width: imageData.width ?? 1024,
          height: imageData.height ?? 1024,
          cfgScale: imageData.guidance_scale,
          steps: imageData.steps ?? 50,
          seed,
        };

        const imageUrl = getImageUrl(imageData?.location);
        const thumbnailUrl = getThumbNailUrl(imageData?.location);

        const images: Compute[] = [];

        const image: Compute = {
          id: imageID,
          created: createdAt,
          prompt: imageData.prompt,
          compute_url: imageUrl,
          thumbnail_url: thumbnailUrl,
        };

        images.push(image);

        imageRecords.push({
          id: imageID,
          created: createdAt,
          task_state: TaskState.COMPLETED,
          input,
          task_type: "TEXT_TO_IMAGE-FIREBASE",
          results: images,
        });
      }
    }

    return imageRecords;
  };

  /**
   * Function to delete task or specific computes
   * @param computeIDs computeids to be deleted within task
   * @param taskid taskid to be deleted
   * @returns
   */
  export const deleteTask = async (options: {
    computeIDs?: AlchemyStudio.ID[];
    taskId: AlchemyStudio.ID;
    accessToken: string;
  }): Promise<void> => {
    const { accessToken, computeIDs, taskId } = { ...options };
    const user_id = await GlobalAPI.getUserId();
    let deleteApiUrl = `/users/${user_id}/tasks/${taskId}`;
    let body = {};
    try {
      if (computeIDs && computeIDs.length > 0) {
        deleteApiUrl = deleteApiUrl + "/computes";
        body = {
          compute_ids: computeIDs,
        };
      }
      await GlobalAPI.fetchData(deleteApiUrl, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${accessToken}` },
        body,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  /**
   * Function to HVP backend call to submit feedback on image output
   * @param options (task and compute details, accesstoken)
   */
  export const submitfeedback = async (options: {
    task: AlchemyStudio.Round;
    accessToken: string;
  }): Promise<void> => {
    const { task, accessToken } = { ...options };
    const user_id = await GlobalAPI.getUserId();
    try {
      await GlobalAPI.fetchData(`/users/${user_id}/tasks/${task.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: { ...task },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
