import { GlobalAPI } from "../GlobalAPI";

export namespace IdpAPI {
  /**
   * API call to get IDP url from backend
   * @param options
   * @returns
   */
  export const verifyUser = async (options: {
    accessToken: string;
  }): Promise<string> => {
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    const { data } = await GlobalAPI.fetchData(`/users/${user_id}/verify`, {
      method: "POST",
      headers: { Authorization: `Bearer ${access_token}` },
    });
    return data?.redirect_to;
  };

  /**
   * API call to get IDP status
   * @param options
   * @returns
   */
  export const getVerificationStatus = async (options: {
    accessToken: string;
  }): Promise<string> => {
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    const { data } = await GlobalAPI.fetchData(`/users/${user_id}/verify`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
    return data?.status;
  };

  /**
   * API call to get trust level
   * @param options
   * @returns
   */
  export const getTrustLevel = async (options: {
    accessToken: string;
  }): Promise<any> => {
    const user_id = await GlobalAPI.getUserId();
    const access_token = options?.accessToken;
    if (!access_token) {
      throw new Error("Failed to get access token");
    }

    const { data } = await GlobalAPI.fetchData(`/users/${user_id}/trust`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return data?.data;
  };

  /**
   * Function to update username
   * @param options (username, accessToken)
   */
  export const updateUsername = async (options: {
    username: string;
    accessToken: string;
  }): Promise<void> => {
    const { username, accessToken } = { ...options };

    try {
      const response = await GlobalAPI.fetchData("/users/username", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: { username },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Function to update user avatar
   * @param options (file, accessToken)
   */
  export const updateAvatar = async (options: {
    file: File | Blob;
    accessToken: string;
  }): Promise<void> => {
    const { file, accessToken } = { ...options };
    try {
      const formData = new FormData();
      formData.append("avatar", file);

      const HOST_API = import.meta.env.VITE_HOST_API;

      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
      };
      delete headers["Content-Type"];

      const response = await fetch(`${HOST_API}/users/avatar`, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      console.log(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
