import ReactLoading from "react-loading";

export function Custom({
  className,
  type,
  color,
  height,
  width,
}: StyleableWithChildren & {
  type: "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spinningBubbles" | "spokes";
  color?: string;
  height?: number;
  width?: number;
}) {
  return (
    <ReactLoading
      type={type}
      color={color}
      className={className}
      height={height}
      width={width}
    />
  );
}
